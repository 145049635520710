// ** Navigation imports
import apps from './apps'
import admin from './admin'
import reportes from './reportes'
// import pages from './pages'
// import forms from './forms'
// import tables from './tables'
// import others from './others'
// import charts from './charts'
import dashboard from './dashboard'
// import uiElements from './ui-elements'

// ** Merge & Export
//const user = JSON.parse(localStorage.getItem('userData'))
//const array = user.role === 'Administrador Operativo' || user.role === 'Administrador Técnico' ? [...apps, ...admin] : [...apps]
export default [...dashboard, ...apps, ...reportes, ...admin]
//export default array
