import toast from 'react-hot-toast'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import 'animate.css/animate.css'
import '@styles/base/plugins/extensions/ext-component-sweet-alerts.scss'
import { getRoutes } from '../router/routes'
//import { matchPath } from 'react-router-dom'


const MySwal = withReactContent(Swal)

export const alertConfirmar = (mensaje) => {
    return new Promise((resolve) => {
        MySwal.fire({
            title: 'Atención',
            text: mensaje,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-danger ms-1'
            },
            buttonsStyling: false
        }).then(function (result) {
            if (result.value) resolve(true)
            else resolve(false)
        })
    })

    //Como usar:
    // alertConfirmar(CONFIRMAR_ELIMINACION).then(async (reponse) => {
    //     if (reponse) {
    //         Success response (presiona confirmar)
    //         
    //         
    //     }
    // });
}

export const alertInfo = (mensaje) => {
    return new Promise((resolve) => {
        MySwal.fire({
            title: 'Éxito',
            text: mensaje,
            icon: 'success',
            showCancelButton: false,
            buttonsStyling: false,
            confirmButtonText: 'Confirmar',
            customClass: {
                confirmButton: 'btn btn-primary'
            }
        }).then(function (result) {
            if (result.value) resolve(true)
            else resolve(false)
        })
    })
}

export const alertCancel = (mensaje) => {
    return new Promise((resolve) => {
        MySwal.fire({
            title: 'Error',
            text: mensaje,
            icon: 'error',
            showCancelButton: false,
            buttonsStyling: false,
            confirmButtonText: 'Confirmar',
            customClass: {
                confirmButton: 'btn btn-primary'
            }
        }).then(function (result) {
            if (result.value) resolve(true)
            else resolve(false)
        })
    })
}

export const alertRedireccion = (titulo, mensaje, url, icono) => {
    return new Promise((resolve) => {
        MySwal.fire({
            title: titulo,
            text: mensaje,
            icon: icono,
            showDenyButton: true,
            confirmButtonText: 'Confirmar',
            denyButtonText: 'Cancelar',
            customClass: {
                confirmButton: 'btn btn-primary',
                denyButton: 'btn btn-danger ms-1'
            },
            buttonsStyling: false
        }).then(function (result) {
            if (result.value) {
                const routes = getRoutes('vertical')
                routes[0].children.forEach((item) => {
                    if (item.path === url) {
                        resolve({status: true, url: `..${url}`})
                    }
                })
                resolve({status: false, url: ''})
            } else {
                resolve({status: null, url: ''})
            }
        }).catch(function () {
            resolve(false)
        })
    })
}

export const alertError = (mensaje) => {
    toast.error(mensaje, { 
        duration: 6000,
        style: {
            minWidth: '520px',
            minHeight: '90px',
            fontWeight: 'bold'
        }
    })
}

export const alertWarning = (mensaje) => {
    toast.loading(mensaje, { 
        duration: 5000,
        icon: null,
        style: {
            minWidth: '520px',
            minHeight: '90px',
            fontWeight: 'bold'
        }
    })
}

// export const alertError = (mensaje) => {
//     const [open, setOpen] = useState(true)
//     return (
//         <div aria-live="polite" aria-atomic="true">
//             <div style={{ position: "fixed", bottom: 0, right: 0, zIndex: 9999, float: "right" }}>
//                 <Toast isOpen={open}>
//                     <ToastHeader toggle={() => setOpen(!open)} icon={<AlertTriangle size={20} color='red'/>}>
//                         Error
//                     </ToastHeader>
//                     <ToastBody>
//                         {mensaje}
//                     </ToastBody>
//                 </Toast>
//             </div>
//         </div>
//     )
// }

export const alertExito = (mensaje) => {
    toast.success(mensaje, { 
        duration: 8000, 
        style: {
            minWidth: '520px',
            minHeight: '90px',
            fontWeight: 'bold'
        }
    })
}

export const alertInformacion = (mensaje) => {
    toast.info(mensaje, { 
        duration: 10000, 
        style: {
            minWidth: '520px',
            minHeight: '90px',
            fontWeight: 'bold'
        }
    })
}