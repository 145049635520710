import axios from "axios"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

export const getData = createAsyncThunk('appCategoriasEntidades/getData', async (param) => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/AprobadoresIntermedios`, { params: param })
    return {
        data: response.data.data.aprobadoresIntermediosRegion[0].procesosUsuarios,
        total: response.data.total,
        users: response.data.data.usuarios,
        params: param
    }
})

export const addAprobadorIntermedio = createAsyncThunk('appaprobInter/addAprobadorIntermedio', async (AprobadorIntermedio, { dispatch, getState }) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/AprobadoresIntermedios`, AprobadorIntermedio)
    await dispatch(getData(getState().aprobadores.params))
    return response.data
})

export const getPerfiles = createAsyncThunk('appaprobInter/getPerfiles', async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/UsuariosPerfiles`)
    return response.data
})

export const getUsuarios = createAsyncThunk('appaprobInter/getUsuarios', async (param) => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/UsuariosInternos`, { params: param })
    return response.data
})


export const appCategoriasEntidades = createSlice({
    name: "appCategoriasEntidades",
    initialState: {
        data: [],
        total: 0,
        totalUsuarios: 0,
        params: {},
        selectedCategoriaEntidad: {}
    },

    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.params = action.payload.params
                state.total = action.payload.total
            })
    }
})

export default appCategoriasEntidades.reducer