// ** React ImportsTransferenciasAsignacion
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

const ProcesosCrear = lazy(() => import('../../views/apps/procesos/crear'))
const ProcesosAdd = lazy(() => import('../../views/apps/procesos/add'))
const ProcesosList = lazy(() => import('../../views/apps/procesos/list'))
const ProcesosCierre = lazy(() => import('../../views/apps/procesos/cierre'))
const ProcesosEdit = lazy(() => import('../../views/apps/procesos/edit'))
const ProcesosPrint = lazy(() => import('../../views/apps/procesos/print'))
const ProcesosView = lazy(() => import('../../views/apps/procesos/view'))

const TransferenciasCargaMasiva = lazy(() => import('../../views/apps/transferencias/cargamasiva'))
const TransferenciasPendientes = lazy(() => import('../../views/apps/transferencias/pendientes'))
const TransferenciasAdd = lazy(() => import('../../views/apps/transferencias/registro'))
const TransferenciasList = lazy(() => import('../../views/apps/transferencias/list'))
const TransferenciasAsignacion = lazy(() => import('../../views/apps/transferencias/asignaciones'))

const ReintegrosPendientes = lazy(() => import('../../views/apps/reintegros/pendientes'))
const ReintegrosAdd = lazy(() => import('../../views/apps/reintegros/registro'))
const ReintegrosList = lazy(() => import('../../views/apps/reintegros/list'))

const TraspasosPendientes = lazy(() => import('../../views/apps/traspasos/pendientes'))
const TraspasosAdd = lazy(() => import('../../views/apps/traspasos/solicitar'))
const TraspasosList = lazy(() => import('../../views/apps/traspasos/list'))

const UsuariosInternosList = lazy(() => import('../../views/apps/usuarios-internos/list'))
const UsuariosInternosView = lazy(() => import('../../views/apps/usuarios-internos/view'))

const UsuariosExternosList = lazy(() => import('../../views/apps/usuarios-externos/list'))
const UsuariosExternosView = lazy(() => import('../../views/apps/usuarios-externos/view'))

const ReporteCD = lazy(() => import('../../views/apps/reportes/reportecd'))
const ReporteHR = lazy(() => import('../../views/apps/reportes/reportehr'))
const ReporteOP = lazy(() => import('../../views/apps/reportes/reporteop'))
const ReporteBeneficiadas = lazy(() => import('../../views/apps/reportes/reportebeneficiadas'))
const ReporteConsolidadoVtf = lazy(() => import('../../views/apps/reportes/reporteconsolidadovtf'))
const ReporteGobiernoTransparente = lazy(() => import('../../views/apps/reportes/reportegobiernotrans'))
const ReporteEjecucionPresupuestaria = lazy(() => import('../../views/apps/reportes/reportepresupuestariavtf'))
const ReporteDeudaHistorica = lazy(() => import('../../views/apps/reportes/reportedeudahistorica'))

const RegionList = lazy(() => import('../../views/apps/regiones/list'))
const RegionView = lazy(() => import('../../views/apps/regiones/view'))

const ProvinciasList = lazy(() => import('../../views/apps/provincias/list'))
const ProvinciasView = lazy(() => import('../../views/apps/provincias/view'))

const ComunasList = lazy(() => import('../../views/apps/comunas/list'))
const ComunasView = lazy(() => import('../../views/apps/comunas/view'))

const EntidadesList = lazy(() => import('../../views/apps/entidades/list'))
const EntidadesView = lazy(() => import('../../views/apps/entidades/view'))

const CategoriaEntidadList = lazy(() => import('../../views/apps/categorias-entidades/list'))
const CategoriaEntidadView = lazy(() => import('../../views/apps/categorias-entidades/view'))

const TiposEntidadesList = lazy(() => import('../../views/apps/tipos-entidades/list'))
const TiposEntidadesView = lazy(() => import('../../views/apps/tipos-entidades/view'))

const RendicionesList = lazy(() => import('../../views/apps/rendiciones/list'))
const RendicionesPendientes = lazy(() => import('../../views/apps/rendiciones/pendientes'))
const RendicionesPreview = lazy(() => import('../../views/apps/rendiciones/preview'))
const RendicionesAdd = lazy(() => import('../../views/apps/rendiciones/add'))
const RendicionesEdit = lazy(() => import('../../views/apps/rendiciones/edit'))
const RendicionesAprobar = lazy(() => import('../../views/apps/rendiciones/aprobar'))
const RendicionesView = lazy(() => import('../../views/apps/rendiciones/viewdetail'))


const EstablecimientosList = lazy(() => import('../../views/apps/establecimientos/list'))
const EstablecimientosView = lazy(() => import('../../views/apps/establecimientos/view'))

const ProveedoresDTEList = lazy(() => import('../../views/apps/proveedores-dte/list'))
const ProveedoresDTEView = lazy(() => import('../../views/apps/proveedores-dte/view'))

const DocumentosList = lazy(() => import('../../views/apps/tipos-documentos/list'))
const DocumentosView = lazy(() => import('../../views/apps/tipos-documentos/view'))

const AprobadoresList = lazy(() => import('../../views/apps/aprobadores-intermedios/list'))
const AprobadoresView = lazy(() => import('../../views/apps/aprobadores-intermedios/view'))
const AprobadoresMod = lazy(() => import('../../views/apps/aprobadores-mod'))

const AppRoutes = [
  {
    element: <ReintegrosList />,
    path: '/apps/reintegros/list',
    meta: {
      action: 'view',
      resource: 'reintegros-listado'
    }
  },
  {
    element: <ReintegrosAdd/>,
    path: '/apps/reintegros/registro',
    meta: {
      action: 'manage',
      resource: 'reintegros-crear'
    }
  },
  {
    element: <ReintegrosAdd/>,
    path: '/apps/reintegros/registro/:id',
    meta: {
      action: 'manage',
      resource: 'reintegros-crear'
    }
  },
  {
    element: <ReintegrosPendientes/>,
    path: '/apps/reintegros/pendientes',
    meta: {
      action: 'view',
      resource: 'reintegros-mispendientes'
    }
  },
  {
    element: <TransferenciasList />,
    path: '/apps/transferencias/list',
    meta: {
      action: 'view',
      resource: 'transferencias-listado'
    }
  },
  {
    element: <TransferenciasAdd/>,
    path: '/apps/transferencias/registro',
    meta: {
      action: 'manage',
      resource: 'transferencias-crear'
    }
  },
  {
    element: <TransferenciasAdd/>,
    path: '/apps/transferencias/registro/:type=:id'
  },
  {
    element: <TransferenciasPendientes/>,
    path: '/apps/transferencias/pendientes',
    meta: {
      action: 'view',
      resource: 'transferencias-mispendientes'
    }
  },
  {
    element: <TransferenciasCargaMasiva/>,
    path: '/apps/transferencias/cargamasiva',
    meta: {
      action: 'manage',
      resource: 'transferencias-cargamasiva'
    }
  },
  {
    element: <TransferenciasAsignacion/>,
    path: '/apps/transferencias/asignacion',
    meta: {
      action: 'view',
      resource: 'transferencias-asignaciones'
    }
  },
  {
    element: <TraspasosList />,
    path: '/apps/traspasos/list',
    meta: {
      action: 'view',
      resource: 'traspasos-listado'
    }
  },
  {
    element: <TraspasosAdd/>,
    path: '/apps/traspasos/solicitar',
    meta: {
      action: 'view',
      resource: 'traspasos-crear'
    }
  },
  {
    element: <TraspasosPendientes/>,
    path: '/apps/traspasos/pendientes',
    meta: {
      action: 'view',
      resource: 'traspasos-mispendientes'
    }
  },
  {
    element: <ProcesosList />,
    path: '/apps/procesos/list',
    meta: {
      action: 'view',
      resource: 'procesos-listado'
    }
  },
  {
    element: <ProcesosCrear />,
    path: '/apps/procesos/crear',
    meta:{
      action: 'manage',
      resource: 'procesos-crear'
    }
  },
  {
    element: <ProcesosView />,
    path: '/apps/procesos/view/:id',
    meta: {
      action: 'view',
      resource: 'procesos-view'
    }
  },
  {
    path: '/apps/procesos/view',
    element: <Navigate to='/apps/procesos/view/4987' />
    /*meta: {
      action: 'read',
      resource: 'ACL'
    }*/
  },
  {
    element: <ProcesosEdit />,
    path: '/apps/procesos/edit/:id'
    /*meta: {
      action: 'read',
      resource: 'ACL'
    }*/
  },
  {
    path: '/apps/procesos/edit',
    element: <Navigate to='/apps/procesos/edit/4987' />
  },
  {
    element: <ProcesosAdd />,
    path: '/apps/procesos/add',
    meta: {
      action: 'manage',
      resource: 'procesos-continuidad'
    }
  },
  {
    element: <ProcesosCierre />,
    path: '/apps/procesos/cierre',
    meta: {
      action: 'manage',
      resource: 'procesos-cierre'
    }
  },
  {
    path: '/apps/procesos/print',
    element: <ProcesosPrint />,
    meta: {
      layout: 'blank'
    }
  },
  {
    element: <RegionList />,
    path: '/apps/regiones/list',
    meta: {
      action: 'manage',
      resource: 'regiones'
    }
  },
  {
    path: '/apps/regiones/view',
    element: <Navigate to='/apps/regiones/view/1' />
  },
  {
    element: <RegionView />,
    path: '/apps/regiones/view/:id'
  },
  {
    element: <ProvinciasList />,
    path: '/apps/provincias/list',
    meta: {
      action: 'manage',
      resource: 'provincias'
    }
  },
  {
    path: '/apps/provincias/view',
    element: <Navigate to='/apps/provincias/view/1' />
  },
  {
    element: <ProvinciasView />,
    path: '/apps/provincias/view/:id'
  },
  {
    element: <ComunasList />,
    path: '/apps/comunas/list',
    meta: {
      action: 'manage',
      resource: 'comunas'
    }
  },
  {
    path: '/apps/comunas/view',
    element: <Navigate to='/apps/comunas/view/1' />
  },
  {
    element: <ComunasView />,
    path: '/apps/comunas/view/:id'
  },
  {
    element: <UsuariosInternosList />,
    path: '/apps/usuarios-internos/list',
    meta: {
      action: 'manage',
      resource: 'usuarios-internos'
    }
  },
  {
    path: '/apps/usuarios-internos/view',
    element: <Navigate to='/apps/usuarios-internos/view/1' />
  },
  {
    element: <UsuariosInternosView />,
    path: '/apps/usuarios-externos/view/:id'
  },
  {
    element: <EstablecimientosList />,
    path: '/apps/establecimientos/list',
    meta: {
      action: 'manage',
      resource: 'establecimientos'
    }
  },
  {
    path: '/apps/establecimientos/view',
    element: <Navigate to='/apps/establecimientos/view/1' />
  },
  {
    element: <EstablecimientosView />,
    path: '/apps/establecimientos/view/:id'
  },
  {
    element: <ProveedoresDTEList />,
    path: '/apps/proveedores-dte/list',
    meta: {
      action: 'manage',
      resource: 'dtes'
    }
  },
  {
    path: '/apps/proveedores-dte/view',
    element: <Navigate to='/apps/proveedores-dte/view/1' />
  },
  {
    element: <ProveedoresDTEView />,
    path: '/apps/proveedores-dte/view/:id'
  },
  {
    element: <CategoriaEntidadList/>,
    path: '/apps/categorias-entidades/list',
    meta: {
      action: 'manage',
      resource: 'categoria-entidad'
    }
  },
  {
    path: '/apps/categorias-entidades/view',
    element: <Navigate to='/apps/categorias-entidades/view/1' />
  },
  {
    element: <CategoriaEntidadView/>,
    path: '/apps/categorias-entidades/view/:id'
  },
  {
    element: <TiposEntidadesList/>,
    path: '/apps/tipos-entidades/list',
    meta: {
      action: 'manage',
      resource: 'tipo-entidad'
    }
  },
  {
    path: '/apps/tipos-entidades/view',
    element: <Navigate to='/apps/tipos-entidades/view/1' />
  },
  {
    element: <TiposEntidadesView/>,
    path: '/apps/tipos-entidades/view/:id'
  },
  {
    element: <DocumentosList />,
    path: '/apps/tipos-documentos/list',
    meta: {
      action: 'manage',
      resource: 'tipos-documento'
    }
  },
  {
    path: '/apps/tipos-documentos/view',
    element: <Navigate to='/apps/tipos-documentos/view/1' />
  },
  {
    element: <DocumentosView />,
    path: '/apps/tipos-documentos/view/:id'
  },
  {
    element: <UsuariosExternosList />,
    path: '/apps/usuarios-externos/list',
    meta: {
      action: 'manage',
      resource: 'usuarios-externos'
    }
  },
  {
    path: '/apps/usuarios-externos/view',
    element: <Navigate to='/apps/usuarios-externos/view/1' />
  },
  {
    element: <UsuariosExternosView />,
    path: '/apps/usuarios-externos/view/:id'
  },
  {
    element: <EntidadesList />,
    path: '/apps/entidades/list',
    meta: {
      action: 'manage',
      resource: 'entidades-administradoras'
    }
  },
  {
    path: '/apps/entidades/view',
    element: <Navigate to='/apps/entidades/view/1' />
  },
  {
    element: <EntidadesView />,
    path: '/apps/entidades/view/:id'
  },
  {
    element: <AprobadoresList/>,
    path: '/apps/aprobadores-intermedios/list',
    meta: {
      action: 'manage',
      resource: 'aprobadores-intermedios'
    }
  },
  {
    path: '/apps/aprobadores-intermedios/view',
    element: <Navigate to='/apps/aprobadores-intermedios/view/1'/>
  },
  {
    element: <AprobadoresView/>,
    path: '/apps/aprobadores-intermedios/view/:id'
  },
  {
    element: <RendicionesList />,
    path: '/apps/rendiciones/list',
    meta: {
      action: 'view',
      resource: 'rendiciones-listado'
    }
  },
  {
    element: <RendicionesPendientes />,
    path: '/apps/rendiciones/pendientes',
    meta: {
      action: 'view',
      resource: 'rendiciones-mispendientes'
    }
  },
  {
    path: '/apps/rendiciones/preview',
    element: <Navigate to='/apps/rendiciones/preview/1' />
  },
  {
    element: <RendicionesPreview />,
    path: '/apps/rendiciones/preview/:id'
  },
  {
    element: <RendicionesAdd />,
    path: '/apps/rendiciones/add/:id',
    meta: {
      action: 'manage',
      resource: 'rendiciones-crear'
    }
  },
  {
    element: <RendicionesAdd />,
    path: '/apps/rendiciones/add',
    meta: {
      action: 'manage',
      resource: 'rendiciones-crear'
    }
  },
  {
    element: <RendicionesAdd />,
    path: '/apps/rendiciones/edit',
    meta: {
      action: 'manage',
      resource: 'rendiciones-crear'
    }
  },
  {
    element: <RendicionesEdit/>,
    path: '/apps/rendiciones/edit/:id',
    meta: {
      action: 'manage',
      resource: 'rendiciones-crear'
    }
  },
  {
    element: <RendicionesView/>,
    path: '/apps/rendiciones/viewdetail/:id',
    meta: {
      action: 'view',
      resource: 'rendiciones-view'
    }
  },
  {
    element: <RendicionesAprobar/>,
    path: '/apps/rendiciones/aprobar/:id',
    meta: {
      action: 'view',
      resource: 'rendiciones-view'
    }
  },
  {
    element: <RendicionesPendientes/>,
    path: '/apps/rendiciones/pendientes',
    meta: {
      action: 'view',
      resource: 'rendiciones-mispendientes'
    }
  },
  {
    element: <ReporteCD/>,
    path: '/apps/reportes/reportecd',
    meta: {
      action: 'view',
      resource: 'reportes-consolidadopcpcd'
    }
  },
  {
    element: <ReporteHR/>,
    path: '/apps/reportes/reportehr',
    meta: {
      action: 'view',
      resource: 'reportes-consolidadopcph'
    }
  },
  {
    element: <ReporteOP/>,
    path: '/apps/reportes/reporteop',
    meta: {
      action: 'view',
      resource: 'reportes-consolidadopcpo'
    }
  },
  {
    element: <ReporteBeneficiadas/>,
    path: '/apps/reportes/reportebeneficiadas',
    meta: {
      action: 'view',
      resource: 'reportes-beneficiadas'
    }
  },
  {
    element: <ReporteConsolidadoVtf/>,
    path: '/apps/reportes/reporteconsolidadovtf',
    meta: {
      action: 'view',
      resource: 'reportes-consolidado-transferenciasvtf'
    }
  },
  {
    element: <ReporteGobiernoTransparente/>,
    path: '/apps/reportes/reportegobiernotrans',
    meta: {
      action: 'view',
      resource: 'reportes-consolidadovtf-gobierno-transparente'
    }
  },
  {
    element: <ReporteEjecucionPresupuestaria/>,
    path: '/apps/reportes/reportepresupuestariavtf',
    meta: {
      action: 'view',
      resource: 'reportes-ejecucion-presupuestariavtf'
    }
  },
  {
    element: <ReporteDeudaHistorica/>,
    path: '/apps/reportes/reportedeudahistorica',
    meta: {
      action: 'view',
      resource: 'reportes-deudahistorica'
    }
  },
  {
    element: <AprobadoresMod/>,
    path: '/apps/aprobadores-mod',
    meta: {
      action: 'manage',
      resource: 'aprobadores-intermedios'
    }
  }
]

export default AppRoutes
