// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appTraspasos/getAllData', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Traspasos`)
  return response.data.allItems
})

export const getData = createAsyncThunk('appTraspasos/getData', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Traspasos`, {params: param})
  return {
    param,
    data: response.data.data !== null ? response.data.data : [],
    totalPages: response.data.total
  }
})

export const getDisponibleParaTraspaso = createAsyncThunk('appTraspasos/getDisponibleParaTraspaso', async (param) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Traspasos/getDisponibleParaTraspasar`, {params: param})
  return response.data
})

export const getTipoProcesos = createAsyncThunk('appTraspasos/getTipoProcesos', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Procesos`)
  return response.data.allItems
})

export const getTiposTransferencias = createAsyncThunk('appTraspasos/getTiposTransferencias', async() => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/TiposTransferencias`)
  return response.data.allItems
})

export const getTransferencia = createAsyncThunk('appTraspasos/getTransferencia', async id => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/TiposTransferencias/${id}`)
  return response.data.data
})

export const getProceso = createAsyncThunk('appTraspasos/getProceso', async id => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Procesos/${id}`)
  return response.data.data
})

export const getEstablecimiento = createAsyncThunk('appTraspasos/getEstablecimiento', async id => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Establecimientos/${id}`)
  return response.data.data
})

export const getEntidadesAdministradoras = createAsyncThunk('appTraspasos/getEntidadesAdministradoras', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/EntidadesAdministradoras`, { params: param})
  return response.data.data
})

export const getEstablecimientos = createAsyncThunk('appTraspasos/getEstablecimientos', async (param) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Establecimientos`, { params: param })
  return response.data.data
})

export const getEstablecimientosByReg = createAsyncThunk('appTraspasos/getEstablecimientos', async (param) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Establecimientos`, { params: param })
  return response.data.data
})


export const crearTraspaso = createAsyncThunk('appTraspasos/crearTraspaso', async (traspaso) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Traspasos/crear`, traspaso)
  return response.data
})

export const getRegiones = createAsyncThunk('appTraspasos/getRegiones', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Regiones`, { params: param })
  return response.data.data
})

export const getTraspaso = createAsyncThunk('appTraspasos/getTraspaso', async id => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Traspasos/${id}`)
  return response.data.data
})

export const getTotales = createAsyncThunk('appTraspasos/getTotales', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Traspasos/totales`)
  return response.data.data
})

export const getPendientes = createAsyncThunk('appTraspasos/getPendientes', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Traspasos/getPendientes`, {params: param })
  return {
    param,
    data: response.data.data !== null ? response.data.data : [],
    totalPages: response.data.total
  }
})
export const autorizarTraspaso = createAsyncThunk('appTraspasos/autorizarTraspaso', async (traspaso, { dispatch, getState }) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/Traspasos/autorizar`, traspaso)
    await dispatch(getPendientes(getState().traspasos.params))
    return response.data
  } catch (err) {
    if (err.code === 'ECONNABORTED') {
      return {
        errores: true,
        mensaje: REQUEST_TIMEOUT
      }
    }
  }
})

export const addTraspaso = createAsyncThunk('appTraspasos/addTraspaso', async (traspaso, { dispatch, getState }) => {
  await axios.post('/apps/traspasos/add', traspaso)
  await dispatch(getData(getState().traspasos.params))
  //await dispatch(getAllData())
  return est
})

export const deleteTraspaso = createAsyncThunk('appTraspasos/deleteTraspaso', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/traspasos/delete', { id })
  await dispatch(getData(getState().traspasos.params))
  //await dispatch(getAllData())
  return id
})

export const downloadExcel = createAsyncThunk('appTraspasos/donwloadExcel', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/traspasos/excel`, {params: param})
  return response
})

export const generarFirmaTraspaso = createAsyncThunk('appTraspasos/generarFirmaTraspaso', async (body) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/Traspasos/generarFirmarCertificado`, body)
  return response.data
})

export const appTraspasosSlice = createSlice({
  name: 'appTraspasos',
  initialState: {
    data: [],
    total: '',
    params: {},
    allData: [],
    pendientes: [],
    selectedTraspaso: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.param
        state.total = action.payload.totalPages
      })
      .addCase(getTraspaso.fulfilled, (state, action) => {
        state.selectedTraspaso = action.payload
      })
      .addCase(addTraspaso.fulfilled, (state, action) => {
        state.data.push(action.payload)
      })
      .addCase(getPendientes.fulfilled, (state, action) => {
        state.pendientes = action.payload.data
        state.params = action.payload.param
        state.total = action.payload.totalPages
      })
    }
})
export default appTraspasosSlice.reducer