// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { alertError } from '../../../../utils/mensajeriaUtil'
// ** Axios Imports
import axios from 'axios'

export const getTransferenciasPend = createAsyncThunk('transferencias/getTransferencias', async param => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Transferencias/getPendientes`, { params: param })
    return {
        param,
        data: response.data.data,
        totalPages: response.data.total
    }
})

export const getAllData = createAsyncThunk('appTransferencias/getAllData', async param => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Transferencias`, { params: param })
    return response.data.allItems
})

export const getTransferTotales = createAsyncThunk('appTransferencias/getTransferTotales', async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Transferencias/totales`)
    return response.data.data
})

export const getEstablecimientos = createAsyncThunk('appTransferencias/getEstablecimientos', async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Establecimientos`, { params: { estado: "Habilitado" } })
    return response.data.data
})

export const getRegiones = createAsyncThunk('appTransferencias/getRegiones', async param => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Regiones`, { params: param })
    return response.data.allItems
})

// export const getTransferenciasPendientes = createAsyncThunk('appTransferencias/getTransferenciasPendientes', async () => {
//   const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Transferencias`)
//   const data = []
//   response.data.allItems.forEach(element => {
//     if (element.estado === 'Pendiente') {
//       data.push(item)
//     }
//   })
//   return data
// })

export const getEstablecimientosByEntidad = createAsyncThunk('appInvoice/getEstablecimientosByEntidad', async param => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Establecimientos`, { params: param })
    return response.data.data
})

export const getData = createAsyncThunk('appInvoice/getData', async param => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Transferencias`, { params: param })
    return {
        param,
        data: response.data.data,
        allData: response.data.allItems,
        totalPages: response.data.total
    }
})

export const getAsignaciones = createAsyncThunk('appTransferencias/getAsignaciones', async param => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Transferencias/getAsignaciones`, { params: param })
    return {
        data: response.data.data,
        params: param,
        allData: response.data.data,
        totalPages: response.data.total
    }
})

export const getTransferencia = createAsyncThunk('appTransferencias/getTransferencia', async id => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Transferencias/${id}`)
    return response.data.data
})

export const getEntidadesAdministradoras = createAsyncThunk('appInvoice/getEntidadesAdministradoras', async param => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/EntidadesAdministradoras`, { params: param })
    return response.data.data
})

export const getEntidadAdministradora = createAsyncThunk('appTransferencias/getEntidadAdministradora', async (id) => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/EntidadesAdministradoras/${id}`)
    return response.data.data
})

export const autorizarTransferencia = createAsyncThunk('appTransferencias/autorizarTransferencia', async (transferencia, { dispatch, getState }) => {
    let err = false
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/Transferencias/autorizar`, transferencia)
        .catch((error) => {
            if (err.code === 'ECONNABORTED') {
                return {
                  errores: true,
                  mensaje: REQUEST_TIMEOUT
                }
            } else if (error.response) {
                const errors = []
                for (const key in error.response.data.errors) {
                    errors.push(error.response.data.errors[key])
                }
                alertError(errors)
                err = true
            }
        })
    await dispatch(getTransferenciasPend(getState().transferencias.params))
    await dispatch(getAsignaciones(getState().transferencias.params))
    return err ? null : response.data
})

export const getTipoProceso = createAsyncThunk('appTransferencias/getTipoProceso', async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Procesos`)
    return response.data.allItems
})

export const preCargarTransferencia = createAsyncThunk('appTransferencias/preCargarTransferencia', async (data) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Transferencias/preCarga`, data)
        return response.data
    } catch (err) {
        if (err.code === 'ECONNABORTED') {
            return {
                errores: true,
                mensaje: REQUEST_TIMEOUT
            }
        }
    }
})

export const procesarCarga = createAsyncThunk('appTransferencias/procesarCarga', async param => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/Transferencias/procesarCarga`, param)
    return response.data
})

export const addTransferencia = createAsyncThunk('appTransferencias/addTransference', async (trans, { dispatch, getState }) => {
    await axios.post('/apps/transferencias/add', trans)
    await dispatch(getData(getState().transferencias.params))
    //await dispatch(getAllData())
    return est
})

export const deleteTransferencia = createAsyncThunk('appTransferencias/deleteTrasferencia', async (id, { dispatch, getState }) => {
    await axios.delete('/apps/transferencias/delete', { id })
    await dispatch(getData(getState().transferencias.params))
    //await dispatch(getAllData())
    return id
})

export const tipoTransferenciasByProceso = createAsyncThunk('appTransferencias/tipoTransferenciasByProceso', async (id) => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Procesos/${id}/tiposTransferencias`)
    return response.data.data
})

export const getPlanilla = createAsyncThunk('appTransferencias/getPlanilla', async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Transferencias/getPlanilla`)
    return response.data.data
})

export const downloadExcel = createAsyncThunk('appTransferencias/donwloadExcel', async param => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Transferencias/getAsignacionesExcel`, { params: param })
    return response
})

export const downloadExcelListado = createAsyncThunk('appTransferencias/downlaodExcelListado', async param => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Transferencias/excel`, { params: param })
    return response
})

export const downloadExcelPendientes = createAsyncThunk('appTransferencias/downloadExcelPendientes', async param => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/transferencias/getPendientesExcel`, { params: param })
    return response
})

export const getTiposTransferencias = createAsyncThunk('appTransferencias/getTiposTransferencias', async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/TiposTransferencias/populate`)
    return response.data.data
})

export const getConvenio = createAsyncThunk('appReintegros/getConvenio', async (param) => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Convenios`, { params: param })
    return response.data.data
})

export const crearTransferencia = createAsyncThunk('appTransferencias/crearTransferencia', async (data, { dispatch, getState }) => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Transferencias/crear`, data)
    await dispatch(getAsignaciones(getState().transferencias.params))
    return response.data
})

export const appTransferenciasSlice = createSlice({
    name: 'appTransferencias',
    initialState: {
        data: [],
        total: '',
        params: {},
        allData: [],
        regiones: [],
        transPend: [],
        totales: { totalTransferencias: 0, totalRealizadas: 0, totalPendientesAut: 0, totalPendientes: 0 },
        entidadesAdministradoras: [],
        establecimientos: [],
        tipoProcesos: [],
        tiposTransferencias: [],
        selectedTrans: null,
        selectedEntidad: null
    },
    reducers: {},
    extraReducers: builder => {
        builder
            // .addCase(getAllData.fulfilled, (state, action) => {
            //   state.allData = action.payload.allData
            // })
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.params = action.payload.param
                state.total = action.payload.totalPages
                state.allData = action.payload.allData

            })
            .addCase(getTransferencia.fulfilled, (state, action) => {
                state.selectedTrans = action.payload
            })
            .addCase(addTransferencia.fulfilled, (state, action) => {
                state.data.push(action.payload)
            })
            // .addCase(getTransferenciasPendientes.fulfilled, (state, action) => {
            //   state.pendientes = action.payload
            // })
            .addCase(getEstablecimientos.fulfilled, (state, action) => {
                state.establecimientos = action.payload
            })
            .addCase(getRegiones.fulfilled, (state, action) => {
                state.regiones = action.payload
            })
            .addCase(getTipoProceso.fulfilled, (state, action) => {
                state.tipoProcesos = action.payload
            })
            .addCase(getEntidadesAdministradoras.fulfilled, (state, action) => {
                state.entidadesAdministradoras = action.payload
            })
            .addCase(getTiposTransferencias.fulfilled, (state, action) => {
                state.tiposTransferencias = action.payload
            })
            .addCase(getEntidadAdministradora.fulfilled, (state, action) => {
                state.selectedEntidad = action.payload
            })
            .addCase(getTransferTotales.fulfilled, (state, action) => {
                state.totales.totalTransferencias = action.payload.totalTransferencias
                state.totales.totalPendientes = action.payload.totalTransferenciasPendientes
                state.totales.totalPendientesAut = action.payload.totalTransferenciasPendientesAutorizacion
                state.totales.totalRealizadas = action.payload.totalTransferenciasRealizadas
            })
            .addCase(getTransferenciasPend.fulfilled, (state, action) => {
                state.transPend = action.payload.data
                state.params = action.payload.param
                state.total = action.payload.totalPages

            })
            .addCase(getAsignaciones.fulfilled, (state, action) => {
                state.allData = action.payload.allData
                state.params = action.payload.params
                state.data = action.payload.data
                state.total = action.payload.totalPages
            })
    }
})
export default appTransferenciasSlice.reducer