import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { REQUEST_TIMEOUT } from '../../../../constants/mensajesConst'

export const getAllData = createAsyncThunk('appdocProv/getAllData', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/DocumentosProveedores`, { params: param })
  return response.data.data
})

export const getData = createAsyncThunk('appdocProv/getData', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/DocumentosProveedores`, { params: param })
  return {
    param,
    data: response.data.data,
    allData: response.data.allItems,
    totalPages: response.data.total
  }
})

export const getDocumentoProveedor = createAsyncThunk('appdocProv/getDocumentoProveedor', async id => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/DocumentosProveedores/${id}`)
  return response.data
})

export const addDocumentoProveedor = createAsyncThunk('appdocProv/addDocumentoProveedor', async (DocumentoProveedor, { dispatch, getState }) => {
  try {
    const response = await axios.post('/api/DocumentosProveedores/crear', DocumentoProveedor)
    await dispatch(getData(getState().proveedores.params))
    return response.data
  } catch (err) {
    if (err.code === 'ECONNABORTED') {
      return {
        errores: true,
        mensaje: REQUEST_TIMEOUT
      }
    }
  }
})

export const saveDocumentoProveedor = createAsyncThunk('appdocProv/saveDocumentoProveedor', async (DocumentoProveedor, { dispatch, getState }) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/DocumentosProveedores`, DocumentoProveedor)
  await dispatch(getData(getState().proveedores.params))
  //await dispatch(getAllData())
  return response.data
})

export const deleteDocumentoProveedor = createAsyncThunk('appdocProv/deleteDocumentoProveedor', async (id, { dispatch, getState }) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/DocumentosProveedores/${id}`)
  await dispatch(getData(getState().proveedores.params))
  //await dispatch(getAllData())
  return response.data
})

export const getTiposDocumentos = createAsyncThunk('appdocProv/getTiposDocumentos', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/DocumentosProveedores/getTiposFacturas`)
  return response.data.data
})

export const downloadExcel = createAsyncThunk('appdocProv/downloadExcel', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/DocumentosProveedores/excel`, { params: param })
  return response
})


export const getProveedor = createAsyncThunk('appdocProv/getProveedor', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Proveedores`, { params: param })
  return response.data.data

})

export const getProveedorByRut = createAsyncThunk('appRendiciones/getProveedorByRut', async (param) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Proveedores/getByRut`, { params: param })
  return response.data.data
})

export const appdocProvSlice = createSlice({
  name: 'appdocProv',
  initialState: {
    data: [],
    proveedores: [],
    total: '',
    params: {},
    allData: [],
    tiposDocumentos: [],
    selectedDocumentoProveedor: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      }).addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      }).addCase(getDocumentoProveedor.fulfilled, (state, action) => {
        state.selectedDocumentoProveedor = action.payload.data
      })
      .addCase(getTiposDocumentos.fulfilled, (state, action) => {
        state.tiposDocumentos = action.payload
      })
      .addCase(getProveedor.fulfilled, (state, action) => {
        state.proveedores = action.payload
      })
  }
})

export default appdocProvSlice.reducer