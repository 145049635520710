// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appReintegros/getAllData', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Reintegros`)
  return response.data.allItems
})

export const getData = createAsyncThunk('appReintegros/getData', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Reintegros`, { params: param })
  return {
    param,
    data: response.data.data !== null ? response.data.data : [],
    totalPages: response.data.total
  }
})

export const getReintegro = createAsyncThunk('appReintegros/getReintegro', async id => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Reintegros/${id}`)
  return response.data
})

export const getTipoProceso = createAsyncThunk('appReintegros/getTipoProceso', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Procesos`)
  return response.data.allItems
})

export const getTipoTransferencia = createAsyncThunk('appInvoice/getTipoTransferencia', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/TiposTransferencias`)
  return response.data.allItems
})

export const getRegiones = createAsyncThunk('appEntidades/getRegiones', async (param) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Regiones`, { params: param })
  return response.data.data
})

export const getEntidades = createAsyncThunk('appReintegros/getEntidades', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/EntidadesAdministradoras`, { params: param })
  return response.data.data
})

export const getEstablecimientos = createAsyncThunk('appReintegros/getEntidades', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Establecimientos`, { params: param })
  return response.data.data
})

export const getEstablecimiento = createAsyncThunk('appReintegros/getEstablecimiento', async (id) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Establecimientos/${id}`)
  return response.data
})

export const getConvenio = createAsyncThunk('appReintegros/getConvenio', async (param) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Convenios`, { params: param })
  return response.data.data
})

export const getDisponibleReintegro = createAsyncThunk('appReintegros/getrDisponibleReintegro', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Reintegros/getDisponibleParaReintegrar`, { params: param })
  return response.data.data
})

export const getTotales = createAsyncThunk('appReintegros/getTotales', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Reintegros/totales`)
  return response.data.data
})


export const addReintegro = createAsyncThunk('appReintegros/addReintegro', async (trans, { dispatch, getState }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Reintegros/crear`, trans)
    await dispatch(getData(getState().reintegros.params))
    return response.data
  } catch (err) {
    if (err.code === 'ECONNABORTED') {
      return {
        errores: true,
        mensaje: REQUEST_TIMEOUT
      }
    }
  }
})

export const deleteReintegro = createAsyncThunk('appReitegros/deleteReintegro', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/reintegros/delete', { id })
  await dispatch(getData(getState().reintegros.params))
  return id
})

export const downloadExcel = createAsyncThunk('appReintegros/downloadExcel', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/reintegros/excel`, { params: param })
  return response
})

export const getPendientes = createAsyncThunk('appReintegros/getPendientes', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Reintegros/getPendientes`, { params:param })
  return {
    param,
    data: response.data.data !== null ? response.data.data : [],
    totalPages: response.data.total
  }
})

export const autorizarReintegro = createAsyncThunk('appReintegros/autorizarReintegros', async (param, { dispatch, getState }) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/Reintegros/autorizar`, param)
    await dispatch(getPendientes(getState().reintegros.params))
    return response.data
  } catch (err) {
    if (err.code === 'ECONNABORTED') {
      return {
        errores: true,
        mensaje: REQUEST_TIMEOUT
      }
    }
  }
})

export const appReintegrosSlice = createSlice({
  name: 'appReintegros',
  initialState: {
    data: [],
    total: '',
    params: {},
    allData: [],
    selectedTrans: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getReintegro.fulfilled, (state, action) => {
        state.selectedTrans = action.payload
      })
      .addCase(addReintegro.fulfilled, (state, action) => {
        state.data.push(action.payload)
      })
      .addCase(getPendientes.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.total = action.payload.totalPages
      })
    }
})
export default appReintegrosSlice.reducer