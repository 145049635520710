import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const getRendicionesPendientes = createAsyncThunk('homePage/getRendicionesPendientes', async (param) => {
    const response = axios.get(`${process.env.REACT_APP_API_URL}/api/Rendiciones/mispendientes`, { params: param })
    return response
})

export const getTraspasosPendientes = createAsyncThunk('homePage/getTraspasosPendientes', async (param) => {
    const response = axios.get(`${process.env.REACT_APP_API_URL}/api/Traspasos/getPendientes`, { params: param })
    return response
})

export const getTransferenciasPendientes = createAsyncThunk('homePage/getTransferenciasPendientes', async (param) => {
    const response = axios.get(`${process.env.REACT_APP_API_URL}/api/Transferencias/getPendientes`, { params: param })
    return response
})

export const getReintegrosPendientes = createAsyncThunk('homePage/getReintegrosPendientes', async (param) => {
    const response = axios.get(`${process.env.REACT_APP_API_URL}/api/Reintegros/getPendientes`, { params: param })
    return response
})

export const homePageSlice = createSlice({
    name: 'homePage',
    initialState: {
        rendicionesPendientes: 0,
        traspasosPendientes: 0,
        transferenciasPendientes: 0,
        reintegrosPendientes: 0
    },
    reducers: {},
    extraReducers: builder => {
        builder
        .addCase(getRendicionesPendientes.fulfilled, (state, action) => {
            state.rendicionesPendientes = action.payload.data.total
        })
        .addCase(getTraspasosPendientes.fulfilled, (state, action) => {
            state.traspasosPendientes = action.payload.data.total
        })
        .addCase(getTransferenciasPendientes.fulfilled, (state, action) => {
            state.transferenciasPendientes = action.payload.data.total
        })
        .addCase(getReintegrosPendientes.fulfilled, (state, action) => {
            state.reintegrosPendientes = action.payload.data.total
        })
    }
})

export default homePageSlice.reducer