import { lazy } from 'react'

const Dashboard = lazy(() => import('../../views/dashboard'))

const DashboardRoutes = [
  {
    path: '/dashboard',
    element: <Dashboard />,
    meta:{
      action: "view",
      resource: "dashboard"
    }
}
]

export default DashboardRoutes
