// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appUsuariosInternos/getAllData', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/UsuariosInternos`)
  return response.data.data
})

export const getData = createAsyncThunk('appUsuariosInternos/getData', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/UsuariosInternos`, {params: param})
  return {
    param,
    data: response.data.data,
    allData: response.data.data,
    totalPages: response.data.total
  }
})

export const getRegiones = createAsyncThunk('appUsuariosInternos/getRegiones', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Regiones`)
  return response.data.data
})

export const getRoles = createAsyncThunk('appUsuariosInternos/getRoles', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/UsuariosPerfiles`)
  return response.data.data
})

export const getUser = createAsyncThunk('appUsuariosInternos/getUser', async id => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/UsuariosInternos/${id}`)
  return response.data.data
})


export const saveUser = createAsyncThunk('appUsuariosInternos/saveUser', async (user, { dispatch, getState }) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/UsuariosInternos`, user)
  await dispatch(getData(getState().usuariosInternos.params))
  ////await dispatch(getAllData())
  return response.data
})

export const addUser = createAsyncThunk('appUsuariosInternos/addUser', async (user, { dispatch, getState }) => { 
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/UsuariosInternos/crear`, user)
  await dispatch(getData(getState().usuariosInternos.params))
  //await dispatch(getAllData())
  return response
})

export const deleteUser = createAsyncThunk('appUsuariosInternos/deleteUser', async (id, { dispatch, getState }) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/UsuariosInternos/${id}`)
  await dispatch(getData(getState().usuariosInternos.params))
  //await dispatch(getAllData())
  return response.data
})

export const downloadExcel = createAsyncThunk('appUsuariosInternos/donwloadExcel', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/UsuariosInternos/excel`, { params:param })
  return response
})

export const appUsersSlice = createSlice({
  name: 'appUsuariosInternos',
  initialState: {
    data: [],
    total: '',
    params: {},
    allData: [],
    regiones: [],
    roles: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.data = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.allData = action.payload.data
        state.total = action.payload.totalPages
        state.params = action.payload.param
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(getRegiones.fulfilled, (state, action) => {
        state.regiones = action.payload
      })
      .addCase(getRoles.fulfilled, (state, action) => {
        state.roles = action.payload
      })
  }
})

export default appUsersSlice.reducer
