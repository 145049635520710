import { TrendingUp, Upload, Clock, Share2, MessageSquare, CheckSquare, Calendar, FileText, Layers, Circle, Clipboard, User, Shield, Users, Eye, MapPin, Repeat, TrendingDown, BarChart } from 'react-feather'

export default [
    {
        header: 'Reportes',
        action: 'view',
        resource: 'reportes'
        },
    {
        id: 'reportesApp',
        title: 'Reportes',
        icon: <FileText size={20} />,
        children: [
            {
                id: 'reportes-conspolidadopcpcd',
                title: 'Consolidado PCPCD',
                icon: <BarChart size={12} />,
                action: 'view',
                resource: 'reportes-consolidadopcpcd',
                navLink: '/apps/reportes/reportecd'
            },
            {
                id: 'reportes-conspolidadopcpo',
                title: 'Consolidado PCPO',
                icon: <BarChart size={12} />,
                action: 'view',
                resource: 'reportes-consolidadopcpo',
                navLink: '/apps/reportes/reporteop'
            },
            {
                id: 'reportes-conspolidadopcph',
                title: 'Consolidado PCPH',
                icon: <BarChart size={12} />,
                action: 'view',
                resource: 'reportes-consolidadopcph',
                navLink: '/apps/reportes/reportehr'
            },
            {
                id: 'reportes-beneficiadas',
                title: 'Beneficiadas HR',
                icon: <BarChart size={12} />,
                action: 'view',
                resource: 'reportes-beneficiadas',
                navLink: '/apps/reportes/reportebeneficiadas'
            },
            {
                id: 'reportes-consolidado-transferenciasvtf',
                title: 'Transferencias VTF',
                icon: <BarChart size={12} />,
                action: 'view',
                resource: 'reportes-consolidado-transferenciasvtf',
                navLink: '/apps/reportes/reporteconsolidadovtf'
            },
            {
                id: 'reportes-consolidadovtf-gobierno-transparente',
                title: 'Gobierno Transparente',
                icon: <BarChart size={12} />,
                action: 'view',
                resource: 'reportes-consolidadovtf-gobierno-transparente',
                navLink: '/apps/reportes/reportegobiernotrans'
            },
            {
                id: 'reportes-ejecucion-presupuestariavtf',
                title: 'Ejec. Presupuestaria',
                icon: <BarChart size={12} />,
                action: 'view',
                resource: 'reportes-ejecucion-presupuestariavtf',
                navLink: '/apps/reportes/reportepresupuestariavtf'
            },
            {
                id: 'reportes-deudahistorica',
                title: 'Deuda Histórica',
                icon: <BarChart size={12} />,
                action: 'view',
                resource: 'reportes-deudahistorica',
                navLink: '/apps/reportes/reportedeudahistorica'
            }
        ]
    }
]
