// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
// import todo from '@src/views/apps/todo/store'
// import chat from '@src/views/apps/chat/store'
// import users from '@src/views/apps/user/store'
// import email from '@src/views/apps/email/store'
// import kanban from '@src/views/apps/kanban/store'
//import invoice from '@src/views/apps/invoice/store'
// import calendar from '@src/views/apps/calendar/store'
// import ecommerce from '@src/views/apps/ecommerce/store'
// import dataTables from '@src/views/tables/data-tables/store'
//import permissions from '@src/views/apps/roles-permissions/store'

// ** junji-s24
import comunas from '@src/views/apps/comunas/store'
import entidades from '@src/views/apps/entidades/store'
import establecimientos from '@src/views/apps/establecimientos/store'
import provincias from '@src/views/apps/provincias/store'
import rendiciones from '@src/views/apps/rendiciones/store'
import regiones from '@src/views/apps/regiones/store'
import usuariosInternos from '@src/views/apps/usuarios-internos/store'
import usuariosExternos from '@src/views/apps/usuarios-externos/store'
import documentos from '@src/views/apps/tipos-documentos/store'
import gastos from '@src/views/apps/rendiciones/add/store'
import categoriasEntidades from '@src/views/apps/categorias-entidades/store'
import tiposEntidades from '@src/views/apps/tipos-entidades/store'
import transferencias from '@src/views/apps/transferencias/store'
import reintegros from '@src/views/apps/reintegros/store'
import traspasos from '@src/views/apps/traspasos/store'
import proveedores from '@src/views/apps/proveedores-dte/store'
import aprobadores from '@src/views/apps/aprobadores-mod/store'
import convenios from '@src/views/apps/procesos/store'
import crearProceso from '@src/views/apps/procesos/crear/store'
import dashboard from '@src/views/dashboard/store'
import reportes from '@src/views/apps/reportes/store'
import homePage from '@src/views/pages/landing/components/store'

const rootReducer = {
  auth,
  provincias,
  regiones,
  comunas,
  entidades,
  rendiciones,
  navbar,
  layout,
  gastos,
  usuariosExternos,
  usuariosInternos,
  establecimientos,
  documentos,
  categoriasEntidades,
  tiposEntidades,
  transferencias,
  reintegros,
  traspasos,
  proveedores,
  aprobadores,
  convenios,
  crearProceso,
  dashboard,
  reportes,
  homePage
}

export default rootReducer
