// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { REQUEST_TIMEOUT } from '../../../../../constants/mensajesConst'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('appRendiciones/getData', async params => {
  const response = await axios.get('/api/gastos/data', params)
  return { allData: response.data.allData, data: response.data.invoices, totalPages: response.data.total, params }
})

export const getTransferencias = createAsyncThunk('appRendiciones/getTransferencias', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Transferencias`)
  return response.data.allItems
})

export const deleteDTE = createAsyncThunk('appRendiciones/deleteDTE', async (idDTE) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/ItemsGastoRendicionDocumentoProveedor/${idDTE}`)
  return response.data
})

export const obtenerDTE = createAsyncThunk('appRendiciones/obtenerDTE', async (idDTE) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/ItemsGastoRendicionDocumentoProveedor/${idDTE}`)
  return response.data
})

export const getTransferenciasParaRendir = createAsyncThunk('appRendiciones/getTransferenciasParaRendir', async (param) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Transferencias/getTransferenciasParaRendir`, { params:param })
  return response.data.data
})

export const getEstablecimientos = createAsyncThunk('appRendiciones/getEstablecimientos', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Establecimientos`)
  return response.data.allItems
})

export const getBitacoras = createAsyncThunk('appRendiciones/getBitacoras', async (id) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Rendiciones/bitacoras/${id}`)
  return response.data
})

export const getDocumentos = createAsyncThunk('appRendiciones/getDocumentos', async (id) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Rendiciones/documentos/${id}`)
  return response.data
})

export const agregarDocumento = createAsyncThunk('appRendiciones/agregarDocumento', async (body) => {
  try {
    const formData = new FormData()
    formData.append("archivo", body.file)
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Rendiciones/${body.rendicionId}/crearDocumento`, formData)
    return response.data
  } catch (err) {
    if (err.code === 'ECONNABORTED') {
      return {
        errores: true,
        mensaje: REQUEST_TIMEOUT
      }
    }
  }
})

export const getDisponibleParaRendir = createAsyncThunk('appRendiciones/getDisponibleParaRendir', async (param) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Transferencias/getDisponibleParaRendir`, { params: param })
  return response.data.data
})

export const crearRendicion = createAsyncThunk('appRendiciones/crearRendicion', async (rendicion) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Rendiciones/crear`, rendicion)
  return response.data
})

export const getTrabajadoras = createAsyncThunk('appRendiciones/getTrabajadoras', async (id) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/ItemsGastoRendicion/${id}/trabajadoras`)
  return response.data.data
})
//
export const getRendiciones = createAsyncThunk('appRendiciones/getRendiciones', async (id) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Rendiciones/${id}`)
  return response.data.data
})

export const crearDocumentoProveedor = createAsyncThunk('appRendiciones/crearDocumentoProveedor', async (docto) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/ItemsGastoRendicionDocumentoProveedor/crear`, docto)
    return response.data
  } catch (err) {
    if (err.code === 'ECONNABORTED') {
      return {
        errores: true,
        mensaje: REQUEST_TIMEOUT
      }
    }
  }
})

export const getProveedorByRut = createAsyncThunk('appRendiciones/getProveedorByRut', async (param) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Proveedores/getByRut`, { params:param })
  return response.data.data
})

export const getDocumentosProveedores = createAsyncThunk('appRendiciones/getDocumentosProveedores', async (param) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/DocumentosProveedores/getByTipoNroRut`, { params:param })
  return response.data.data
})

export const getDTEItemGasto = createAsyncThunk('appRendiciones/getDTEItemGasto', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/ItemsGastoRendicionDocumentoProveedor`, { params: param })
  return response.data.data
})

export const getTiposFacturas = createAsyncThunk('appRendiciones/getTiposFacturas', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/DocumentosProveedores/getTiposFacturas`)
  return response.data.data
})

export const getResumenSaldos = createAsyncThunk('appRendiciones/getResumenSaldos', async (id) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Rendiciones/${id}/getResumenSaldos`)
  return response.data
})

export const getEstadosRendicion = createAsyncThunk('appRendiciones/getEstadosRendicion', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/EstadosRendicion`)
  return response.data.data
})

export const enviarAprobacion = createAsyncThunk('appRendiciones/enviarAprobacion', async (body) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/Rendiciones/enviarAprobacion`, body)
  return response.data
})

export const actualizarRendicion = createAsyncThunk('appRendiciones/actualizarRendicion', async (data) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/Rendiciones`, data)
  return response.data
})

export const getPlanilla = createAsyncThunk('appRendiciones/getPlanilla', async (param) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Trabajadoras/getPlanillaRendicion`, { params:param })
  return response.data
})

export const uploadPlanilla = createAsyncThunk('appRendiciones/uploadPlanilla', async param => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Trabajadoras/uploadPlanillaRendicion`, param)
  return response.data
})

export const cambiarMonto = createAsyncThunk('appRendiciones/cambiarMonto', async param => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/ItemsGastoRendicionTrabajadora/setMonto`, param)
  return response.data
})

export const getItemsGasto = createAsyncThunk('appRendiciones/getItemsGasto', async id => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Rendiciones/${id}/itemsGasto`)
  return response.data
})

export const crearDocumentoTrabajadora = createAsyncThunk('appRendiciones/crearDocumentoTrabajadora', async param => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/ItemsGastoRendicionTrabajadora/crearDocumento`, param)
    return response.data
  } catch (err) {
    if (err.code === 'ECONNABORTED') {
      return {
        errores: true,
        mensaje: REQUEST_TIMEOUT
      }
    }
  }
})

export const listadoDocumentosTrabajadora = createAsyncThunk('appRendiciones/listadoDocumentosTrabajadoras', async (id) => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/ItemsGastoRendicionTrabajadora/documentos/${id}`)
    return response.data
})

export const getDocumentosRendicion = createAsyncThunk('appRendiciones/getDocumentosRendicion', async (id) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/ItemsGastoRendicion/documentos/${id}`)
  return response.data
})

export const crearDocumentoRendicion = createAsyncThunk('appRendiciones/crearDocumentoRendicion', async (body) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/ItemsGastoRendicion/crearDocumento`, body)
    return response.data
  } catch (err) {
    if (err.code === 'ECONNABORTED') {
      return {
        errores: true,
        mensaje: REQUEST_TIMEOUT
      }
    }
  }
})

export const eliminarDocumento = createAsyncThunk('appRendiciones/eliminarDocumento', async (id) => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/documentos/${id}`)
    return response.data
})

export const gastosSlice = createSlice({
  name: 'gastos',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    transferencias: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
    .addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
    })
    .addCase(getTransferencias.fulfilled, (state, action) => {
      state.transferencias = action.payload
    })
  }
})

export default gastosSlice.reducer
