import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
// ** Redux Imports


export const getReporteConsolidadoCd = createAsyncThunk('reportes/getreporteConsolidadoCd', async param => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Reporte/reporteConsolidadoCD`, { params: param })
    return response.data
})

export const getReporteConsolidadoHr = createAsyncThunk('reportes/getreporteConsolidadoHr', async param => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Reporte/reporteConsolidadoHR`, { params: param })
    return response.data
})

export const getReporteConsolidadoOp = createAsyncThunk('reportes/getreporteConsolidadoOp', async param => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Reporte/reporteConsolidadoOP`, { params: param })
    return response.data
})

export const getReporteEjecucionPresupuestaria = createAsyncThunk('reportes/getreporteEjecucionPresupuesto', async param => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Reporte/reporteEjecucionPresupuestariaVTF`, { params: param })
    return response.data
})

export const getReporteGobiernoTransparente = createAsyncThunk('reportes/getreporteConsolidadoOp', async param => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Reporte/reporteVTFGobiernoTransparente`, { params: param })
    return response.data
})

export const getReporteConsolidadoVTF = createAsyncThunk('reportes/getreporteConsolidadoVTF', async param => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Reporte/reporteConsolidadoTransferenciaVTF`, { params: param })
    return response.data
})  

export const getReporteBeneficiadasHomologacion = createAsyncThunk('reportes/getreporteBeneficiadasHomologacion', async param => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Reporte/reporteBeneficiadasHomologacion`, { params: param })
    return response.data
})

export const downloadExcelDeudaHistorica = createAsyncThunk('reportes/downloadExcelDeudaHistorica', async param => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Reporte/DeudaHistoricaExcel`, { params: param })
    return response.data
})

export const getTotalesDH = createAsyncThunk('reportes/getTotalesDH', async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Reporte/getTotalesDeudaHistorica`)
    return response.data
})

export const getDeudaHistorica = createAsyncThunk('reportes/getData', async param => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Reporte/DeudaHistorica`, {params:param})
    return {
      param,
      data: response.data.data !== null ? response.data.data : [],
      allData: response.data.data !== null ? response.data.data : [],
      totalPages: response.data.total
    }
  })

  export const appReporteSlice = createSlice({
    name: 'appReporte',
    initialState: {
      data: [],
      total: '',
      params: {},
      allData: [],
      totales: {}
    },
    reducers: {},
    extraReducers: builder => {
      builder.addCase(getDeudaHistorica.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.total = action.payload.totalPages
        state.params = action.payload.param
        state.allData = action.payload.allData
      })
      .addCase(getTotalesDH.fulfilled, (state, action) => {
        state.totales = action.payload.data
      })
    }
    })
    
export default appReporteSlice.reducer