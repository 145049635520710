// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appUsuariosExternos/getAllData', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/UsuariosExternos`)
  return response.data.allItems
})

export const getData = createAsyncThunk('appUsuariosExternos/getData', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/UsuariosExternos`, {params: param})
  return {
    param,
    data: response.data.data,
    allData: response.data.allItems,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('appUsuariosExternos/getUser', async id => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/UsuariosExternos/${id}`)
  return response.data.data
})

export const addUser = createAsyncThunk('appUsuariosExternos/addUser', async (user, { dispatch, getState }) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/UsuariosExternos/crear`, user)
  await dispatch(getData(getState().usuariosExternos.params))
  //await dispatch(getAllData())
  return response.data
})

export const saveUser = createAsyncThunk('appUsuariosExternos/saveUser', async (user, { dispatch, getState }) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/UsuariosExternos`, user)
  await dispatch(getData(getState().usuariosExternos.params))
  //await dispatch(getAllData())
  return response.data
})

export const deleteUser = createAsyncThunk('appUsuariosExternos/deleteUser', async (id, { dispatch, getState }) => {
  const response = axios.delete(`${process.env.REACT_APP_API_URL}/api/UsuariosExternos/${id}`)
  await dispatch(getData(getState().usuariosExternos.params))
  //await dispatch(getAllData())
  return response
})

export const getRegiones = createAsyncThunk('appUsuariosExternos/getRegiones', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Regiones`)
  return response.data.allItems
})

export const getPerfilUsuario = createAsyncThunk('appUsuariosExternos/getPerfilUsuario', async (id) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/UsuariosPerfiles/${id}`)
  return response.data
})

export const getPerfiles = createAsyncThunk('appUsuariosExternos/getPerfiles', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/UsuariosPerfiles`)
  return response.data
})

export const getEntidadesAdmin = createAsyncThunk('appUsuariosExternos/getEntidadesAdministradoras', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/EntidadesAdministradoras`, {params: param})
  return response.data.data
})

export const downloadExcel = createAsyncThunk('appUsuariosExternos/donwloadExcel', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/UsuariosExternos/excel`, {params: param})
  return response
})

export const appUsersSlice = createSlice({
  name: 'appUsuariosExternos',
  initialState: {
    data: [],
    total: '',
    params: {},
    allData: [],
    regiones: [],
    profileData: [],
    entityData: [],
    selectedUser: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.param
        state.total = action.payload.totalPages
        state.allData = action.payload.allData
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = {}
        state.selectedUser = action.payload
      })
      .addCase(getEntidadesAdmin.fulfilled, (state, action) => {
        state.entityData = action.payload
      })
      .addCase(getRegiones.fulfilled, (state, action) => {
        state.regiones = action.payload
      })
  }
})

export default appUsersSlice.reducer
