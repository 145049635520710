// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
// import { dispatch } from 'react-hot-toast/dist/core/store'

export const getAllData = createAsyncThunk('appComunas/getAllData', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Comunas`)
  return response.data.data
  // const data = []
  // return data
})

export const getData = createAsyncThunk('appComunas/getData', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Comunas`, { params: param })
  return {
  param,
  data: response.data.data,
  totalPages: response.data.total}
})

export const getProvinciasByRegion = createAsyncThunk('appProvincias/getProvinciasByRegion', async (id, data) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Regiones/${id}/provincias`, {params : data.body})
  return response.data.data
})

// export const getComunaByProv = createAsyncThunk('appEstablecimientos/getComunasByProv', async (data) => {
//   const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Provincias/${data.id_prov}/comunas`, {params: data.body})
//   return response.data.data
// })

export const getComuna = createAsyncThunk('appComunas/getComuna', async id => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Comunas/${id}`)
  return response.data.data
})

export const addComuna = createAsyncThunk('appComunas/addComuna', async (Comuna, { dispatch, getState }) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/comunas/crear`, Comuna)
  await dispatch(getData(getState().comunas.params))
  //await dispatch(getAllData())
  return response.data
})

export const deleteComuna = createAsyncThunk('appComunas/deleteComuna', async (id, { dispatch, getState }) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/Comunas/${id}`)
  await dispatch(getData(getState().comunas.params))
  //await dispatch(getAllData())
  return response.data
})

export const saveComuna = createAsyncThunk('appComunas/saveComuna', async (Comuna, { dispatch, getState }) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/Comunas`, Comuna)
  await dispatch(getData(getState().comunas.params))
  //await dispatch(getAllData())
  return response.data
})

export const getProvincias = createAsyncThunk('appComunas/getProvincias', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Provincias`, {params: param})
  return response.data.data
})

export const getRegiones = createAsyncThunk('appComunas/getRegiones', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Regiones`, {params: param})
  return response.data.data
})

export const downloadExcel = createAsyncThunk('appComunas/downloadExcel', async params => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/comunas/excel`, params)
  return response
})

export const appComunasSlice = createSlice({
  name: 'appComunas',
  initialState: {
    data: [],
    total: '',
    params: {},
    allData: [],
    provincias: [],
    regiones: [],
    selectedComuna: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getComuna.fulfilled, (state, action) => {
        state.selectedComuna = action.payload
      })
      .addCase(getProvincias.fulfilled, (state, action) => {
        state.provincias = action.payload
      })
      .addCase(getRegiones.fulfilled, (state, action) => {
        state.regiones = action.payload
      })
  }
})

export default appComunasSlice.reducer
