// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appDocumentos/getAllData', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/TiposDocumentos`)
  return response.data.allItems
})

export const getData = createAsyncThunk('appDocumentos/getData', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/TiposDocumentos`, {params: param})
  return {
    param,
    data: response.data.data,
    allData: response.data.allItems,
    totalPages: response.data.total
  }
})

export const getDocumento = createAsyncThunk('appDocumentos/getDocumento', async id => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/TiposDocumentos/${id}`)
  return response.data.data
})

export const getCatDocumentos = createAsyncThunk('appDocumentos/getCatDocumento', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/CategoriasDocumentos`)
  return response.data.data
})

export const addDocumento = createAsyncThunk('appDocumentos/addDocumento', async (Documento, { dispatch, getState }) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/TiposDocumentos/crear`, Documento)
  await dispatch(getData(getState().documentos.params))
  //await dispatch(getAllData())
  return response.data
})

export const saveDocumento = createAsyncThunk('appDocumentos/saveDocumento', async (Documento, { dispatch, getState }) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/TiposDocumentos`, Documento)
  await dispatch(getData(getState().documentos.params))
  //await dispatch(getAllData())
  return response.data
})

export const deleteDocumento = createAsyncThunk('appDocumentos/deleteDocumento', async (id, { dispatch, getState }) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/TiposDocumentos/${id}`)
  await dispatch(getData(getState().documentos.params))
  //await dispatch(getAllData())
  return response
})

export const downloadExcel = createAsyncThunk('appDocumentos/downloadExcel', async params => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/TiposDocumentos/excel`, params)
  return response
})

export const appDocumentosSlice = createSlice({
  name: 'appDocumentos',
  initialState: {
    data: [],
    total: '',
    params: {},
    allData: [],
    catDocumento: [],
    selectedDocumento: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.allData = action.payload.allData
        state.total = action.payload.totalPages
      })
      .addCase(getDocumento.fulfilled, (state, action) => {
        state.selectedDocumento = action.payload
      })
      .addCase(getCatDocumentos.fulfilled, (state, action) => {
        state.catDocumento = action.payload
      })
  }
})

export default appDocumentosSlice.reducer