export const CAMPO_REQUERIDO = 'Este campo es requerido'

export const ERROR_GENERICO = 'Ha ocurrido un error en la operación, por favor intente nuevamente'

export const OPERACION_EXITOSA = 'Operación realizada con éxito'

export const CONFIRMAR_ELIMINACION = '¿Está seguro que desea eliminar?'

export const EMAIL_NO_VALIDO = 'Correo no es válido'

export const CREDENCIALES_NO_VALIDAS = 'Credenciales no válidas'

export const DATA_TABLE_EMPTY_MESSAGE = "No se encontraron registros"

export const RUT_NO_VALIDO = 'Rut no es válido'

export const CELULAR_NO_VALIDO = 'Celular no es válido'

export const CERRAR_PROYECTO = '¿Está seguro que desea cerrar el proyecto?'

export const USUARIO_SIN_PERMISOS = 'No tiene permisos para realizar esta acción'

export const SIN_DATOS_EXPORTAR = 'No se encontraron datos para exportar'

export const CORREO_ENVIADO = 'Se ha enviado un correo para restablecer su clave a su cuenta de correo electrónico'

export const CAMBIO_DE_CLAVE = 'Se restableció la clave de forma exitosa'

export const SELECT_EMPTY_MESSAGE = 'Seleccione una opción'

export const INSERTAR_CODIGO_GESPARVU = 'Ingrese el código de GESPARVU para continuar'

export const ARCHIVO_SUPERA_TAMANO_MAXIMO = 'El tamaño máximo de cada archivo es 60MB, favor intente subir otro archivo.'

export const REQUEST_TIMEOUT = 'Error al procesar la solicitud, valide su conexión a internet y vuelva a intentar más tarde.'