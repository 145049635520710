// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appRendiciones/getAllData', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Rendiciones`, {params : param})
  return response.data.allItems
})

export const getFirmasElectronicas = createAsyncThunk('appRendiciones/getFirmasElectronicas', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Rendiciones/getFirmasElectronicas`)
  return response.data
})

export const generarFirma = createAsyncThunk('appRendiciones/generarFirma', async body => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/Rendiciones/generarFirmarCertificado`, body)
  return response.data
})

export const eliminarInformeRendicion = createAsyncThunk('appRendiciones/eliminaraInformeRendicion', async (id) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/Rendiciones/eliminarCertificadosRendicion/${id}`)
  return response.data
})

export const revisionItemsGasto = createAsyncThunk('appRendiciones/revisionItemsGasto', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/RevisionesItemGastoRendicion/getByItemGastoRendicionId`, {params:param})
  return response.data
})

export const getDocumentosItemGastoRendicion = createAsyncThunk('appRendiciones/getDocumentosItemGastoRendicion', async (id) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/ItemsGastoRendicion/documentos/${id}`)
  return response.data
})

export const revisionItemsGastoTrabajadoras = createAsyncThunk('appRendiciones/revisionItemsGastoTrabajadoras', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/RevisionesItemGastoRendicionTrabajadora/getByTrabajadoraId`, {params: param})
  return response.data
})

export const getTrabajadora = createAsyncThunk('appRendiciones/ItemsGastoRendicionTrabajadora', async (id) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/ItemsGastoRendicionTrabajadora/${id}`)
  return response.data
})

export const actualizarObservacion = createAsyncThunk('appRendiciones/setObservacion', async (body) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/ItemsGastoRendicionTrabajadora/setMontoObservacionEstado`, body)
  return response.data
})

export const getData = createAsyncThunk('appRendiciones/getData', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Rendiciones`, {params:param})
  return { 
    param,
    data: response.data.data,
    allData: response.data.allItems, 
    totalPages: response.data.total
  }
})

export const getRegiones = createAsyncThunk('regiones/getRegiones', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Regiones`, {params:param})
  return response.data.allItems
})

export const getTipoProceso = createAsyncThunk('appTransferencias/getTipoProceso', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Procesos`)
  return response.data.allItems
})

export const getEntidadesAdministradoras = createAsyncThunk('appInvoice/getEntidadesAdministradoras', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/EntidadesAdministradoras`, {params: param})
  return response.data.allItems
})

export const getEstablecimientosByEntidad = createAsyncThunk('appInvoice/getEstablecimientosByEntidad', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Establecimientos`, {params: param})
  return response.data.data
})

// export const getEstablecimientosByRegion = createAsyncThunk('appInvoice/getEstablecimientosByRegion', async param => {
//   const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Establecimientos`, {params: param})
//   return response.data.data

// })

// export const getRegionesByEntidad = createAsyncThunk('appInvoice/getRegionesByEntidad', async (id) => {
//   const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Regiones`)
//   const data = []
//   response.data.allItems.forEach((item) => {
//     if (item.entidadAdministradoraId === id) {
//       data.push(item)
//     }
//   })
//   return data
// })

export const getEstadosTotales = createAsyncThunk('appTransferencias/getEstadoTotales', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Rendiciones/totales`)
  return response.data.data
})
  

export const getEstadoRendicion = createAsyncThunk('appTransferencias/getEstadosRendiciones', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/EstadosRendicion/populate`)
  return response.data.data
  })

  
export const getTipoTransferencia = createAsyncThunk('appInvoice/getTipoTransferencia', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/TiposTransferencias/populate`, {params: param})
  return response.data.data
})

// export const getEstablecimientos = createAsyncThunk('appInvoice/getEstablecimientos', async () => {
//   const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Establecimientos`)
//   return response.data.allItems
// })

export const getRendicion = createAsyncThunk('appRendiciones/getRendicion', async (id) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Rendiciones/${id}`)
  return response.data
})

export const getPendientes = createAsyncThunk('appRendiciones/getPendientes', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Rendiciones/mispendientes`, {params:param})
  return {
    param,
    data: response.data.data,
    allData: response.data.allItems,
    totalPages: response.data.total
  }
})

export const getItemsGasto = createAsyncThunk('appRendiciones/getItemsGasto', async (id) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Rendiciones/${id}/itemsGasto`)
  return response.data
})

export const enviarAprobacion = createAsyncThunk('appRendiciones/enviarAprobacion', async (body) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/Rendiciones/enviarAprobacion`, body)
  return response
})

export const addRendicion = createAsyncThunk('appRendiciones/addRendicion', async (rendicion, { dispatch, getState }) => {
  await axios.post('/apps/rendiciones/add-rendicion', rendicion)
  await dispatch(getData(getState().rendiciones.params))
  //await dispatch(getAllData())
  return rendicion
})

export const deleteRendicion = createAsyncThunk('appRendiciones/deleteRendicion', async (id, { dispatch, getState }) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/Rendiciones/${id}`)
  await dispatch(getPendientes(getState().rendiciones.params))
  return response.data
})

export const downloadExcel = createAsyncThunk('appRendiciones/downloadExcel', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/rendiciones/excel`, {params:param})
  return response
})

export const aprobarRendicion = createAsyncThunk('appRendicion/aprobarRendicion', async payload => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/Rendiciones`, payload)
  return response
})

export const firmarRendicion = createAsyncThunk('appRendiciones/firmarRendicion', async (param) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/Rendiciones/generarFirmarCertificadoAprobacion`, param)
  return response.data
})

export const documentosRendicion = createAsyncThunk('appRendiciones/documentosRendicion', async (rendicionId) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Rendiciones/documentos/${rendicionId}`)
  return response.data
})

export const documetosTrabajadora = createAsyncThunk('appRendiciones/documentosTrabajadora', async(idTrabajadora) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/ItemsGastoRendicionTrabajadora/documentos/${idTrabajadora}`)
  return response.data
})

export const appRendicionesSlice = createSlice({
  name: 'appRendiciones',
  initialState: {
    data: [],
    regiones: [],
    tipoTransferencia: [],
    tipoProceso: [],
    estadoRendicion: [],
    entidadesAdministradoras: [],
    establecimientos: [],
    totales:{totalRendicion:0, totalRendicionAprob:0, totalRendicionPend:0, totalRendicionCerrada:0},
    total: '',
    estadoRendicion: [],
    params: {},
    allData: [],
    selectedRendicion: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.param
        state.total = action.payload.totalPages
        state.allData = action.payload.allData
      })
      .addCase(getRendicion.fulfilled, (state, action) => {
        state.selectedRendicion = action.payload
      })
      .addCase(getRegiones.fulfilled, (state, action) => {
        state.regiones = action.payload
      })
      .addCase(getTipoProceso.fulfilled, (state, action) => {
        state.tipoProceso = action.payload
      })
      .addCase(getEntidadesAdministradoras.fulfilled, (state, action) => {
        state.entidadesAdministradoras = action.payload
      })
      // .addCase(getEstablecimientos.fulfilled, (state, action) => {
      //   state.establecimientos = action.payload
      // })
      .addCase(getTipoTransferencia.fulfilled, (state, action) => {
        state.tipoTransferencia = action.payload
      })
      .addCase(getEstadoRendicion.fulfilled, (state, action) => {
        state.estadoRendicion = action.payload
      })
      .addCase(getEstadosTotales.fulfilled, (state, action) => {
        state.totales.totalRendicion = action.payload.totalRendiciones
        state.totales.totalRendicionAprob = action.payload.totalRendicionesEnAprobacion
        state.totales.totalRendicionPend = action.payload.totalRendicionesPendientes
        state.totales.totalRendicionCerrada = action.payload.totalRendicionesCerradas
      })
      .addCase(getPendientes.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.param
        state.total = action.payload.totalPages
      })
  }
})

export default appRendicionesSlice.reducer
