// ** Icons Import
import { Briefcase, Sunrise, CheckSquare, Calendar, FileText, Layers, Circle, ShoppingCart, User, Shield, Users, Eye, MapPin } from 'react-feather'

export default [
  {
    header: 'Administración',
    action: 'manage',
    resource: 'regiones'
  },
  {
    id: 'regionesApp',
    title: 'Regiones',
    icon: <MapPin size={20} />,
    children: [
      {
        id: 'regionesList',
        title: 'Listado',
        icon: <Circle size={12} />,
        action: 'manage',
        resource: 'regiones',
        navLink: '/apps/regiones/list'
      }//,
      // {
      //   id: 'RegionesPreview',
      //   title: 'Ver',
      //   icon: <Circle size={12} />,
      //   navLink: '/apps/regiones/preview'
      // }      
    ]
  },
  {
    id: 'provinciasApp',
    title: 'Provincias',
    icon: <MapPin size={20} />,
    children: [
      {
        id: 'provinciasList',
        title: 'Listado',
        action: 'manage',
        resource: 'provincias',
        icon: <Circle size={12} />,
        navLink: '/apps/provincias/list'
      }//,
      // {
      //   id: 'RegionesPreview',
      //   title: 'Ver',
      //   icon: <Circle size={12} />,
      //   navLink: '/apps/provincias/preview'
      // }      
    ]
  },
  {
    id: 'comunasApp',
    title: 'Comunas',
    icon: <MapPin size={20} />,
    children: [
      {
        id: 'comunasList',
        title: 'Listado',
        action: 'manage',
        resource: 'comunas',
        icon: <Circle size={12} />,
        navLink: '/apps/comunas/list'
      }//,
      // {
      //   id: 'ComunasPreview',
      //   title: 'Ver',
      //   icon: <Circle size={12} />,
      //   navLink: '/apps/comunas/preview'
      // }
    ]
  },
  {
    id: 'usuariosInternosApp',
    title: 'Usuarios Internos',
    icon: <Users size={20} />,
    children: [
      {
        id: 'usuariosInternosList',
        title: 'Listado',
        icon: <Circle size={12} />,
        action: 'manage',
        resource: 'usuarios-internos',
        navLink: '/apps/usuarios-internos/list'
      }//,
      // {
      //   id: 'usuariosInternosPreview',
      //   title: 'Ver',
      //   icon: <Circle size={12} />,
      //   navLink: '/apps/usuarios-internos/preview'
      // }      
    ]
  },
  {
    id: 'usuariosExternosApp',
    title: 'Usuarios Externos',
    icon: <Users size={20} />,
    children: [
      {
        id: 'usuariosExternosList',
        title: 'Listado',
        icon: <Circle size={12} />,
        navLink: '/apps/usuarios-externos/list',
        action: 'manage',
        resource: 'usuarios-externos'
      }//,
      // {
      //   id: 'usuariosExternosPreview',
      //   title: 'Ver',
      //   icon: <Circle size={12} />,
      //   navLink: '/apps/usuarios-externos/preview'
      // }      
    ]
  },
  {
    id: 'establecimientosApp',
    title: 'Establecimientos',
    icon: <Sunrise size={20} />,
    children: [
      {
        id: 'establecimientosList',
        title: 'Listado',
        icon: <Circle size={12} />,
        action: 'manage',
        resource: 'establecimientos',
        navLink: '/apps/establecimientos/list'
      }//,
      // {
      //   id: 'establecimientosPreview',
      //   title: 'Ver',
      //   icon: <Circle size={12} />,
      //   navLink: '/apps/establecimientos/preview'
      // }
    ]
  },
  {
    id: 'entidadesAdministrdorasApp',
    title: 'Entidades Adm.',
    icon: <Briefcase size={20} />,
    children: [
      {
        id: 'entidadesAdministradorasList',
        title: 'Listado',
        icon: <Circle size={12} />,
        action: 'manage',
        resource: 'entidades-administradoras',
        navLink: '/apps/entidades/list'
      }//,
      // {
      //   id: 'entidadesAdministradorasPreview',
      //   title: 'Ver',
      //   icon: <Circle size={12} />,
      //   navLink: '/apps/entidades/preview'
      // }     
    ]
  },
  {
    id: 'documentosApp',
    title: 'Tipos de Documento',
    icon: <FileText />,
    children: [
      {
        id: 'documentosList',
        title: 'Listado',
        action: 'manage',
        resource: 'tipos-documento',
        icon: <Circle size={12} />,
        navLink: '/apps/tipos-documentos/list'
      }//,
      // {
      //   id: 'documentosPreview',
      //   title: 'Ver',
      //   icon: <Circle size={12} />,
      //   navLink: '/apps/tipos-documentos/preview'
      // }
    ]
  },
  {
    id: 'categoriaEntidadApp',
    title: 'Categoría de Entidad',
    icon: <Layers />,
    children: [
      {
        id: 'categoriaEntidadList',
        title: 'Listado',
        action: 'manage',
        resource: 'categoria-entidad',
        icon: <Circle size={12} />,
        navLink: '/apps/categorias-entidades/list'
      }
    ]
  },
  {
    id: 'tipoEntidadApp',
    title: 'Tipo de Entidad',
    icon: <Layers />,
    children: [
      {
        id: 'tipoEntidadList',
        title: 'Listado',
        action: 'manage',
        resource: 'tipo-entidad',
        icon: <Circle size={12} />,
        navLink: '/apps/tipos-entidades/list'
      }
    ]
  },
  {
    id: 'proveedorDTEApp',
    title: 'Documentos Proveedores',
    icon: <Layers />,
    children: [
      {
        id: 'proveedorDTEAppList',
        title: 'Listado',
        action: 'manage',
        resource: 'dtes',
        icon: <Circle size={12} />,
        navLink: '/apps/proveedores-dte/list'
      }
    ]
  },
  // {
  //   id: 'aprobadoresApp',
  //   title: 'Aprobadores Intermedios',
  //   icon: <Layers />,
  //   children: [
  //     {
  //       id: 'aprobadoresAppList',
  //       title: 'Listado',
  //       action: 'manage',
  //       resource: 'aprobadores-intermedios',
  //       icon: <Circle size={12} />,
  //       navLink: '/apps/aprobadores-intermedios/list'
  //     }
  //   ]
  // },
  {
    id: 'aprobadoresMod',
    title: 'Aprobadores Intermedios(MOD)',
    icon: <Layers />,
    children: [
      {
        id: 'aprobadoresModList',
        title: 'Listado',
        action: 'manage',
        resource: 'aprobadores-intermedios',
        icon: <Circle size={12} />,
        navLink: '/apps/aprobadores-mod'
      }
    ]
  }
]
