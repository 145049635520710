// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appEntidades/getAllData', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/EntidadesAdministradoras`)
  return {
    allItems: response.data.allItems,
    total: response.data.total
  }
})

export const getData = createAsyncThunk('appEntidades/getData', async (param) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/EntidadesAdministradoras`, {params: param})
  return {
    param,
    data: response.data.data,
    allData: response.data.allItems,
    totalPages: response.data.total
  }
})

export const getEntidad = createAsyncThunk('appEntidades/getEntidad', async id => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/EntidadesAdministradoras/${id}`)
  return response.data.data
})

export const addEntidad = createAsyncThunk('appEntidades/addEntidad', async (entidad, { dispatch, getState }) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/EntidadesAdministradoras/crear`, entidad)
  await dispatch(getData(getState().entidades.params))
  //await dispatch(getAllData())
  return response.data
})

export const saveEntidad = createAsyncThunk('appEntidades/saveEntidad', async (entidad, { dispatch, getState }) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/EntidadesAdministradoras`, entidad)
  await dispatch(getData(getState().entidades.params))
  //await dispatch(getAllData())
  return response.data
})

export const deleteEntidad = createAsyncThunk('appEntidades/deleteEntidad', async (id, { dispatch, getState }) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/EntidadesAdministradoras/${id}`)
  await dispatch(getData(getState().entidades.params))
  //await dispatch(getAllData())
  return response.data
})

export const getRegiones = createAsyncThunk('appEntidades/getRegiones', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Regiones`)
  return response.data.allItems
})

export const getTiposEntidades = createAsyncThunk('appEntidades/getTiposEntidades', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/TiposEntidadesAdministradoras`)
  return response.data.data
})

export const downloadExcel = createAsyncThunk('appEntidades/downloadExcel', async (param) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/EntidadesAdministradoras/excel`, {params: param})
  return response.data
})

export const appEntidadesSlice = createSlice({
  name: 'appEntidades',
  initialState: {
    data: [],
    total: 1,
    enabled: 0,
    disabled: 0,
    pending: 0,
    regiones: [],
    params: {},
    allData: [],
    entityTypes: [],
    selectedEntidad: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload.allItems
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.param
        //state.allData = action.payload.allData
        state.total = action.payload.totalPages
      })
      .addCase(getEntidad.fulfilled, (state, action) => {
        state.selectedEntidad = action.payload
      })
      .addCase(getTiposEntidades.fulfilled, (state, action) => {
        state.entityTypes = action.payload
      })
      .addCase(getRegiones.fulfilled, (state, action) => {
        state.regiones = action.payload
      })
  }
})

export default appEntidadesSlice.reducer
