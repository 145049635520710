import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const getAllData = createAsyncThunk('apptiposEntidades/getAllData', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/TiposEntidadesAdministradoras`)
  return response.data.data
})

export const getData = createAsyncThunk('apptiposEntidades/getData', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/TiposEntidadesAdministradoras`, {params: param})
  return {
    param,
    data: response.data.data,
    //allData: response.data.allItems,
    totalPages: response.data.total
  }
})

export const getTipoEntidad = createAsyncThunk('apptiposEntidades/getTipoEntidad', async id => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/TiposEntidadesAdministradoras/${id}`)
  return response.data.data
})

export const addTipoEntidad = createAsyncThunk('apptiposEntidades/addTipoEntidad', async (TipoEntidad, { dispatch, getState }) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/TiposEntidadesAdministradoras/crear`, TipoEntidad)
  await dispatch(getData(getState().tiposEntidades.params))
  //await dispatch(getAllData())
  return response.data
})

export const saveTipoEntidad = createAsyncThunk('apptiposEntidades/saveTipoEntidad', async (TipoEntidad, { dispatch, getState }) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/TiposEntidadesAdministradoras`, TipoEntidad)
  await dispatch(getData(getState().tiposEntidades.params))
  //await dispatch(getAllData())
  return response.data
})

export const deleteTipoEntidad = createAsyncThunk('apptiposEntidades/deleteTipoEntidad', async (id, { dispatch, getState }) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/TiposEntidadesAdministradoras/${id}`)
  await dispatch(getData(getState().tiposEntidades.params))
  //await dispatch(getAllData())
  return response.data
})

export const getCategoriasEntidades = createAsyncThunk('appUsuariosExternos/getCategoriasEntidades', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/CategoriasEntidadesAdministradoras`)
  return response.data.data
})

export const downloadExcel = createAsyncThunk('apptiposEntidades/downloadExcel', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/TiposEntidadesAdministradoras/excel`, {params: param})
  return response.data
})

export const apptiposEntidadesSlice = createSlice({
  name: 'apptiposEntidades',
  initialState: {
    data: [],
    total: '',
    params: {},
    allData: [],
    catEntidad: [],
    selectedTipoEntidad: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      }).addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.param
        state.total = action.payload.totalPages
      }).addCase(getTipoEntidad.fulfilled, (state, action) => {
        state.selectedTipoEntidad = action.payload
      })
      .addCase(getCategoriasEntidades.fulfilled, (state, action) => {
        state.catEntidad = action.payload
      })
  }
})

export default apptiposEntidadesSlice.reducer