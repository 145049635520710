// ** Icons Import
import { TrendingUp, Upload, Clock, Share2, MessageSquare, CheckSquare, Calendar, FileText, Layers, Circle, Clipboard, User, Shield, Users, Eye, MapPin, Repeat, TrendingDown } from 'react-feather'

export default [
  {
    header: 'Menú Principal',
    action: 'view',
    resource: 'procesos-listado'
  },
  {
    id: 'procesosApp',
    title: 'Procesos',
    icon: <Layers size={20} />,
    children: [
      {
        id: 'procesosList',
        title: 'Listado',
        action: 'view',
        resource: 'procesos-listado',
        icon: <Circle size={12} />,
        navLink: '/apps/procesos/list'
      },
      {
        id: 'procesosCrear',
        title: 'Crear',
        icon: <Circle size={12} />,
        action: 'manage',
        resource: 'procesos-crear',
        navLink: '/apps/procesos/crear'
      },
      {
        id: 'procesosContinuidad',
        title: 'Continuidad',
        action: 'manage',
        resource: 'procesos-continuidad',
        icon: <Circle size={12} />,
        navLink: '/apps/procesos/add'
      },
      {
        id: 'procesosCierre',
        title: 'Discontinuidad',
        action: 'manage',
        resource: 'procesos-cierre',
        icon: <Circle size={12} />,
        navLink: '/apps/procesos/cierre'
      }
      // ,
      // {
      //   id: 'procesosEdit',
      //   title: 'Editar',
      //   icon: <Circle size={12} />,
      //   navLink: '/apps/procesos/edit'
      // },
      // {
      //   id: 'procesosAdd',
      //   title: 'Agregar',
      //   icon: <Circle size={12} />,
      //   navLink: '/apps/procesos/add'
      // }
    ]
  },
  {
    id: 'rendicionesApp',
    title: 'Rendiciones',
    icon: <Clipboard size={20} />,
    children: [
      {
        id: 'rendicionesList',
        title: 'Listado',
        action: 'view',
        resource: 'rendiciones-listado',
        icon: <Circle size={12} />,
        navLink: '/apps/rendiciones/list'
      },
      {
        id: 'pendientesList',
        title: 'Mis pendientes',
        action: 'view',
        resource: 'rendiciones-mispendientes',
        icon: <Circle size={12} />,
        navLink: '/apps/rendiciones/pendientes'
      },
      //{
      //   id: 'rendicionesPreview',
      //   title: 'Ver',
      //   icon: <Circle size={12} />,
      //   navLink: '/apps/rendiciones/preview'
      //},
      // 0{
      //   id: 'procesosEdit',
      //   title: 'Editar',
      //   icon: <Circle size={12} />,
      //   navLink: '/apps/procesos/edit'
      // },
      {
        id: 'rendicionesAdd',
        title: 'Preparar',
        action: 'view',
        resource: 'rendiciones-crear',
        icon: <Circle size={12} />,
        navLink: '/apps/rendiciones/add'
      }
    ]
  },
  {
    id: 'transferenciasApp',
    title: 'Transferencias',
    icon: <TrendingUp size={20} />,
    children: [
      {
        id: 'transferenciasList',
        title: 'Listado',
        icon: <Circle size={12} />,
        action: 'view',
        resource: 'transferencias-listado',
        navLink: '/apps/transferencias/list'
      },
      {
        id: 'pendientesList',
        title: 'Mis pendientes',
        icon: <Clock size={12} />,
        action: 'view',
        resource: 'transferencias-mispendientes',
        navLink: '/apps/transferencias/pendientes'
      },
      {
        id: 'registroAdd',
        title: 'Registrar nueva',
        icon: <CheckSquare size={12} />,
        action: 'manage',
        resource: 'transferencias-crear',
        navLink: '/apps/transferencias/registro'
      },
      {
        id: 'cargamasivaAdd',
        title: 'Carga Masiva',
        action: 'view',
        resource: 'transferencias-cargamasiva',
        icon: <Upload size={12} />,
        navLink: '/apps/transferencias/cargamasiva'
      },
      {
        id: 'asignacion',
        title: 'Asignaciones',
        action: 'view',
        resource: 'transferencias-asignaciones',
        icon: <Share2 size={12}/>,
        navLink: '/apps/transferencias/asignacion'
      }
    ]
  },
  {
    id: 'reintegrosApp',
    title: 'Reintegros',
    icon: <TrendingDown size={20} />,
    children: [
      {
        id: 'reintegrosList',
        title: 'Listado',
        icon: <Circle size={12} />,
        action: 'view',
        resource: 'reintegros-listado',
        navLink: '/apps/reintegros/list'
      },
      {
        id: 'pendientesList',
        title: 'Mis pendientes',
        icon: <Clock size={12} />,
        action: 'view',
        resource: 'reintegros-mispendientes',
        navLink: '/apps/reintegros/pendientes'
      },
      {
        id: 'registroAdd',
        title: 'Registrar nuevo',
        icon: <CheckSquare size={12} />,
        action: 'manage',
        resource: 'reintegros-crear',
        navLink: '/apps/reintegros/registro'
      }
    ]
  },
  {
    id: 'traspasosApp',
    title: 'Traspaso de Recursos',
    icon: <Repeat size={20} />,
    children: [
      {
        id: 'TraspasosList',
        title: 'Listado',
        icon: <Circle size={12} />,
        action: 'view',
        resource: 'traspasos-listado',
        navLink: '/apps/traspasos/list'
      },
      {
        id: 'pendientesList',
        title: 'Mis pendientes',
        action: 'view',
        resource: 'traspasos-mispendientes',
        icon: <Clock size={12} />,
        navLink: '/apps/traspasos/pendientes'
      },
      {
        id: 'solicitarAdd',
        title: 'Solicitar',
        icon: <Upload size={12} />,
        action: 'manage',
        resource: 'traspasos-crear',
        navLink: '/apps/traspasos/solicitar'
      }
    ]
  }
]
