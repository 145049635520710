// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getRegiones = createAsyncThunk('appDashboard/getRegiones', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Regiones/populate`, {params:param})
  return response.data.data
})

export const getTransferencia = createAsyncThunk('appDashboard/getTransferencia', async id => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Transferencias/${id}`)
  return response.data.data
})

export const getProcesos = createAsyncThunk('appDashboard/getProcesos', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Procesos/populate`)
  return response.data.data
})

export const getTrabajadoras = createAsyncThunk('appDashboard/getTrabajadoras', async (params) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Dashboard/trabajadoras`, {params})
  return response.data.data
})

export const getTrasferidoCargo = createAsyncThunk('appDashboard/getTrasferidoCargo', async (params) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Dashboard/transferidoCargo`, {params})
  return response.data.data
})

export const getEjecucionPresupuestaria = createAsyncThunk('appDashboard/getEjecucionPresupuestaria', async (params) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Dashboard/ejecucionPresupuestaria`, {params})
  return response.data.data
})

export const getEjecucionGasto = createAsyncThunk('appDashboard/getEjecucionGasto', async (params) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Dashboard/ejecucionGasto`, {params})
  return response.data.data
})

export const getEntidadesAdministradoras = createAsyncThunk('global/getEntidadesAdministradoras', async (param) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/EntidadesAdministradoras/populate`, {params: param})
  return response.data.data
})

export const getEstablecimientos = createAsyncThunk('global/getEstablecimientos', async (param) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Establecimientos/populate`, {params: param})
  return response.data.data
})

export const descargarExcel = createAsyncThunk('appDashboard/descargarExcel', async (param) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Dashboard/excel`, { params: param })
  return response.data
})

export const ejecucionSaldoExcel = createAsyncThunk('appDashboard/ejecucionSaldoExcel', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Dashboard/ejecucionSaldoExcel`)
  return response.data
})

export const ejecucionPresupuestariaExcel = createAsyncThunk('appDashboard/ejecucionPresupuesetariaExcel', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Dashboard/ejecucionPresupuestariaExcel`)
  return response.data
})

export const trabajadorasExcel = createAsyncThunk('appDashboard/trabajadorasExcel', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Dashboard/trabajadorasExcel`)
  return response.data
})

export const transferidoCargoExcel = createAsyncThunk('appDashboard/transferidoCargoExcel', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Dashboard/transferidoCargoExcel`)
  return response.data
})

export const appDashboardSlice = createSlice({
  name: 'appDashboard',
  initialState: {
    params: {},
    allData: [],
    regiones: [],
    trasferidoCargo: [],
    ejecucionPresupuestaria: [],
    ejecucionGasto: [],
    trabajadoras: [],
    entidadesAdministradoras: [],
    establecimientos: [],
    procesos: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getRegiones.fulfilled, (state, action) => {
      state.regiones = action.payload
    })
    .addCase(getTrabajadoras.fulfilled, (state, action) => {
      state.trabajadoras = action.payload
    })
    .addCase(getTrasferidoCargo.fulfilled, (state, action) => {
      state.trasferidoCargo = action.payload
    })
    .addCase(getEjecucionPresupuestaria.fulfilled, (state, action) => {
      state.ejecucionPresupuestaria = action.payload
    })
    .addCase(getEjecucionGasto.fulfilled, (state, action) => {
      state.ejecucionGasto = action.payload
    })
    // .addCase(getEntidadesAdministradoras.fulfilled, (state, action) => {
    //   state.entidadesAdministradoras = action.payload
    // })
    .addCase(getEstablecimientos.fulfilled, (state, action) => {
      state.establecimientos = action.payload
    })
    .addCase(getProcesos.fulfilled, (state, action) => {
      state.procesos = action.payload
    })
  }
})


export default appDashboardSlice.reducer
