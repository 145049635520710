// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

/*
"appProcesos" => {
  allData => {guardar retorno de http://localhost/api/Convenios},
  ...
}
*/
export const getAllData = createAsyncThunk('appProcesos/getAllData', async() => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Convenios`)
  return response.data.allItems
})

export const getData = createAsyncThunk('appProcesos/getData', async params => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Convenios`, params)
  return { 
    allData: response.data.allItems, 
    data: response.data.data, 
    totalPages: response.data.total, 
    params
  }
})

export const getTipoProceso = createAsyncThunk('appProcesos/getTipoProceso', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Procesos`)
return response.data.data
})

export const addProceso = createAsyncThunk('appProcesos/addProceso', async (Documento, { dispatch, getState }) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Convenios/crear`, Documento)
  await dispatch(getData(getState().documentos.params))
  //await dispatch(getAllData())
  return response.data
})

export const getEntidadesAdministradoras = createAsyncThunk('appProcesos/getEntidadesAdministradoras', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/EntidadesAdministradoras`, {params: {estado:"Habilitado"}})
  return response.data.data
})

export const getEstablecimientos = createAsyncThunk('appProcesos/getEstablecimientos', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Establecimientos`, { params: {estado:"Habilitado"} })
  return response.data.data
})

export const getEstablecimientosByEntidad = createAsyncThunk('appInvoice/getEstablecimientosByEntidad', async (id) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Establecimientos`, {params: {estado: "Habilitado"}})
  const data = []
  response.data.data.forEach((item) => {
    if (item.entidadAdministradoraId === id) {
      data.push(item)
    }
  })
  return data
})


export const gastosSlice = createSlice({
  name: 'gastos',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    entidadesAdministradoras: [],
    establecimientos: [],
    procesos: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
    .addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
    })
    .addCase(getAllData.fulfilled, (state, action) => {
      state.allData = action.payload
    })
    .addCase(getEntidadesAdministradoras.fulfilled, (state, action) => {
      state.entidadesAdministradoras = action.payload
    })
    .addCase(getEstablecimientos.fulfilled, (state, action) => {
      state.establecimientos = action.payload
    })
    .addCase(getTipoProceso.fulfilled, (state, action) => {
      state.procesos = action.payload
    })
  }
})

export default gastosSlice.reducer
