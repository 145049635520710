// ** Auth Endpoints
export default {  
  loginEndpoint: `/api/authentication/sign-in`,
  forgotPasswordEndpoint: `/api/authentication/forgot-password`,
  resetPasswordEndpoint: `/api/authentication/reset-password`,
  registerEndpoint: '/jwt/register',
  refreshEndpoint: `/api/authentication/refresh-token`,
  logoutEndpoint: '/jwt/logout',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
}
