import { lazy } from "react"

const Home = lazy(() => import('../../views/pages/landing'))

const HomeRoute = [
    {
        path: '/home',
        element: <Home/>,
        meta: {
            action: 'view',
            resource: 'home'
        }
    }
]

export default HomeRoute