import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const getAllData = createAsyncThunk('appcatEntidades/getAllData', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/CategoriasEntidadesAdministradoras`)
  return response.data.data
})

export const getData = createAsyncThunk('appcatEntidades/getData', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/CategoriasEntidadesAdministradoras`, {params: param})
  return {
    param,
    data: response.data.data,
    allData: response.data.allItems,
    totalPages: response.data.total
  }
})
/*export const getData = createAsyncThunk('appcatEntidades/getData', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/CategoriasEntidadesAdministradoras`)
  return {
    params,
    data: response.data.data,
    allData: response.data,
    totalPages: response.data.total
  }
})*/

export const getCategoriaEntidad = createAsyncThunk('appcatEntidades/getCategoriaEntidad', async id => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/CategoriasEntidadesAdministradoras/${id}`)
  return response.data.data
})

export const addCategoriaEntidad = createAsyncThunk('appcatEntidades/addCategoriaEntidad', async (CategoriaEntidad, { dispatch, getState }) => {
  const response = await axios.post('/api/categoriasentidadesadministradoras/crear', CategoriaEntidad)  
  // await axios.post(`${process.env.REACT_APP_API_URL}/api/CategoriasEntidadesAdministradoras/crear`, CategoriaEntidad)
  await dispatch(getData(getState().categoriasEntidades.params))
  //await dispatch(getAllData())
  return response.data
})

export const saveCategoriaEntidad = createAsyncThunk('appcatEntidades/saveCategoriaEntidad', async (CategoriaEntidad, { dispatch, getState }) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/CategoriasEntidadesAdministradoras`, CategoriaEntidad)
  await dispatch(getData(getState().categoriasEntidades.params))
  //await dispatch(getAllData())
  return response.data
})

export const deleteCategoriaEntidad = createAsyncThunk('appcatEntidades/deleteCategoriaEntidad', async (id, { dispatch, getState }) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/CategoriasEntidadesAdministradoras/${id}`)
  await dispatch(getData(getState().categoriasEntidades.params))
  //await dispatch(getAllData())
  return response.data
})

export const downloadExcel = createAsyncThunk('appcatEntidades/downloadExcel', async params => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/CategoriasEntidadesAdministradoras/excel`, params)
  return response
})

export const appcatEntidadesSlice = createSlice({
  name: 'appcatEntidades',
  initialState: {
    data: [],
    total: '',
    params: {},
    allData: [],
    selectedCategoriaEntidad: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      }).addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      }).addCase(getCategoriaEntidad.fulfilled, (state, action) => {
        state.selectedCategoriaEntidad = action.payload
      })
  }
})

export default appcatEntidadesSlice.reducer