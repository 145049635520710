// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getComunaById = createAsyncThunk('appEstablecimientos/getComunaById', async (id_comuna) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Comunas/${id_comuna}`, {params: {estado : "Habilitado"}})
  return response.data.data
})

export const getProvinciaById = createAsyncThunk('appEstablecimientos/getProvinciaById', async (id_provincia) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Provincias/${id_provincia}`, {params: {estado : "Habilitado"}})
  return response.data.data
})

export const getRegionById = createAsyncThunk('appEstablecimientos/getRegionById', async (id_region) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Regiones/${id_region}`)
  return response.data.data
})
// export const getAllData = createAsyncThunk('appEstablecimientos/getAllData', async () => {
//   const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Establecimientos`)
//   return response.data.allItems

// })

export const getData = createAsyncThunk('appEstablecimientos/getData', async (param) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Establecimientos`, {params: param})
  return {
    param,
    data: response.data.data,
    allData: response.data.data,
    totalPages: response.data.total
  }
})

export const getEstablecimiento = createAsyncThunk('appEstablecimientos/getEstablecimiento', async id => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Establecimientos/${id}`)
  return response.data.data
})

export const addEstablecimiento = createAsyncThunk('appEstablecimientos/addEstablecimiento', async (est, { dispatch, getState }) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Establecimientos/crear`, est)
  await dispatch(getData(getState().establecimientos.params))
  return response.data
})

export const saveEstablecimiento = createAsyncThunk('appEstablecimientos/saveEstablecimiento', async (est, { dispatch, getState }) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/Establecimientos`, est)
  await dispatch(getData(getState().establecimientos.params))
  return response.data
})

export const deleteEstablecimiento = createAsyncThunk('appEstablecimientos/deleteEstablecimiento', async (id, { dispatch, getState }) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/Establecimientos/${id}`)
  await dispatch(getData(getState().establecimientos.params))
  return response.data
})

export const downloadExcel = createAsyncThunk('appEstablecimientos/downloadExcel', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Establecimientos/excel`, {params: param})
  return response

})

export const getRegiones = createAsyncThunk('appEstablecimientos/getRegiones', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Regiones`, {params: param})
  return response.data.data
})

export const getProvinciaByReg = createAsyncThunk('appEstablecimientos/getProvinciaByReg', async (data) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Regiones/${data.id_reg}/provincias`, {params: data.body})
  return response.data.data
})

export const getProvincias = createAsyncThunk('appEstablecimientos/getProvincias', async (param) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Provincias`, {params: param})
  return response.data.data
})

export const getComunaByProv = createAsyncThunk('appEstablecimientos/getComunasByProv', async (data) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Provincias/${data.id_prov}/comunas`, {params: data.body})
  return response.data.data
})

export const getComunas = createAsyncThunk('appEstablecimientos/getComunas', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Comunas`, {params : param})
  return response.data
})

export const getEntidadesAdmin = createAsyncThunk('appEstablecimientos/getEntidadesAdmin', async (param) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/EntidadesAdministradoras`, {params: param})
  return response.data.data
})

export const getCodigoGesparvu = createAsyncThunk('appEstablecimientos/getCodigoGesparvu', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Establecimientos/getByCodigoGesparvu`, {params: param})
  return response.data
})

export const appEstablecimientosSlice = createSlice({
  name: 'appEstablecimientos',
  initialState: {
    data: [],
    total: '',
    params: {},
    allData: [],
    regiones: [],
    provincias: [],
    entidades: [],
    comunas: [],
    selectedComuna: null,
    selectedProvincia: null,
    selectedRegion: null,
    selectedEst: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      // .addCase(getAllData.fulfilled, (state, action) => {
      //   state.allData = action.payload
      // })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.param
        state.allData = action.payload.data
        state.total = action.payload.totalPages
      })
      .addCase(getEstablecimiento.fulfilled, (state, action) => {
        state.selectedEst = action.payload
      })
      .addCase(getRegiones.fulfilled, (state, action) => {
        state.regiones = action.payload
      })
      .addCase(getProvinciaByReg.fulfilled, (state, action) => {
        state.provincias = action.payload
      })
      .addCase(getComunaByProv.fulfilled, (state, action) => {
        state.comunas = action.payload
      })
      .addCase(getEntidadesAdmin.fulfilled, (state, action) => {
        state.entidades = action.payload
      })
      .addCase(getProvincias.fulfilled, (state, action) => {
        state.provincias = action.payload
      })
      .addCase(getComunaById.fulfilled, (state, action) => {
        state.selectedComuna = action.payload
      })
      .addCase(getProvinciaById.fulfilled, (state, action) => {
        state.selectedProvincia = action.payload
      })
      .addCase(getRegionById.fulfilled, (state, action) => {
        state.selectedRegion = action.payload
      })
  }
})

export default appEstablecimientosSlice.reducer
