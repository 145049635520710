// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { ERROR_GENERICO, REQUEST_TIMEOUT } from '../../../../constants/mensajesConst'
import { alertError } from '../../../../utils/mensajeriaUtil'

export const getRegiones = createAsyncThunk('regiones/getRegiones', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Regiones/populate`, {params:param})
  return response.data.data
})

export const getAllData = createAsyncThunk('appInvoice/getAllData', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Convenios`)
  .catch((error) => {
    if (error.response) {
      alertError(`${error.message}.\n Código error: ${error.code}`)
    } else {
      alertError(ERROR_GENERICO)
    }
  })
  return response.data.data
})

export const getPendientes = createAsyncThunk('appInvoice/getPendientes', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Continuidad/getPendientesAutorizacion`) 
  return response.data.data
})

export const getPlanilla = createAsyncThunk('appInvoice/getPlanilla', async() => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/convenios/getPlanilla`)
  return response.data
})

export const getPlanillaTrabajadorasConvenio = createAsyncThunk('appInvoice/getPlanillaTrabajadorasConvenio', async (param) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Trabajadoras/getPlanillaConvenio`, { params:param })
  return response.data
})

export const uploadPlanilla = createAsyncThunk('appInvoice/uploadPlanilla', async (file) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Convenios/uploadPlanilla`, file, {timeout: 30000})
    return response.data
  } catch (err) {
    if (err.code === 'ECONNABORTED') {
      return {
        errores: true,
        mensaje: "Su carga puede demorarse en cargar. Procesaremos la carga y le notificaremos por correo con el resultado de la misma."
      }
    }
  }
})

export const uploadPlanillaTrabajadorasConvenio = createAsyncThunk('appInvoice/uploadPlanillaTrabajadorasConvenio', async param => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Trabajadoras/uploadPlanillaConvenio`, param)
  return response.data
})

export const getTipoProceso = createAsyncThunk('appTransferencias/getTipoProceso', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Procesos`)
  return response.data.allItems
})


export const getTransferencia = createAsyncThunk('appTransferencias/getTransferencia', async id => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Transferencias/${id}`)
  return response.data.data
})

export const postCartaCierre = createAsyncThunk('appTransferencias/postCartaCierre', async (payload) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Continuidad/autorizar`, payload)
  return response.data
})

export const getEntidadesAdministradoras = createAsyncThunk('appInvoice/getEntidadesAdministradoras', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/EntidadesAdministradoras/populate`, {params:param})
  return response.data.data
})

export const getEstablecimientosByEntidad = createAsyncThunk('appInvoice/getEstablecimientosByEntidad', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Establecimientos`, {params:param})
  return response.data.data
})

export const getEstablecimientosByRegion = createAsyncThunk('appInvoice/getEstablecimientosByRegion', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Establecimientos`, {params: param})
  return response.data.data
})

export const getTrabajadoras = createAsyncThunk('appInvoice/getTrabajadoras', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Trabajadoras`, {params: param})
  return response.data.data
})

export const getEstadosTotales = createAsyncThunk('appInvoice/getEstadosTotales', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Convenios/totales`)
  return response.data.data
})

export const getConvenio = createAsyncThunk('appProcesos/getConvenio', async (id) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Convenios/${id}`)
  // return response.data
  return {
    selected: response.data.data,
    data: response.data
  }
})

export const getConvenioByContinuidad = createAsyncThunk('appProcesos/getConvenioByContinuidad', async (id) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Convenios/getByContinuidad/${id}`)
  return {
    Id: id, 
    selected: response.data.data,
    data: response.data
  }
})

export const getProcesos = createAsyncThunk('appInvoice/getProcesos', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Procesos/populate`)
  return response.data.data
})

export const getEstablecimientos = createAsyncThunk('appInvoice/getEstablecimientos', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Establecimientos`)
  return response.data.allItems
})

export const getData = createAsyncThunk('appInvoice/getData', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Convenios`, {params:param})
  return {
    param,
    data: response.data.data !== null ? response.data.data : [],
    allData: response.data.data !== null ? response.data.data : [],
    totalPages: response.data.total
  }
})

export const uploadDocumento = createAsyncThunk('appInvoice/uploadDocumento', async (payload) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Convenios/uploadDocumento`, payload)
    return response.data
  } catch (err) {
    if (err.code === 'ECONNABORTED') {
      return {
        errores: true,
        mensaje: REQUEST_TIMEOUT
      }
    }
  }
})

export const deleteDocumento = createAsyncThunk('appInvoice/deleteDocumento', async (param) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/Convenios/documento`, {params:param})
  return response.data
})

export const getDocumentos = createAsyncThunk('appInvoice/getDocumentos', async (param) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Convenios/documentos`, {params:param})
  return response.data
})


export const getPendienteProceso = createAsyncThunk('appInvoice/getPendienteProceso', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Continuidad/getPendientesAutorizacion`, {params:param})
  return {
    param,
    data: response.data.data !== null ? response.data.data : [],
    allData: response.data.data !== null ? response.data.data : [],
    totalPages: response.data.total
  }
})

export const getMontoTransferencia = createAsyncThunk('appInvoice/getMontoTransferencia', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Convenios/getResumenSaldos`, { params: param })
  return response.data
})

export const downloadExcel = createAsyncThunk('appInvoice/downloadExcel', async param => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Convenios/excel`, { params:param })
  return response
})

export const getCuentaCorriente = createAsyncThunk('appInvoice/getCuentaCorriente', async id => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Convenios/getCuentaCorriente/${id}`)
  return response.data
})

export const deleteProceso = createAsyncThunk('appInvoice/deleteProceso', async (id, {dispatch, getState}) => {
  await axios.delete('/apps/procesos/delete', { id })
  await dispatch(getData(getState().proceso.params))
  return id
})


export const getNominaTrabajadoras = createAsyncThunk('appInvoice/getNominaTrabajadoras', async (convenioId) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Convenios/getNominaTrabajadoras/${convenioId}`)
  return response.data
})

export const actualizarPresupuesto = createAsyncThunk('appInvoice/actualizarPresupuesto', async (body) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/Convenios/updatePresupuesto`, body)
  return response.data
})

export const appProcesoSlice = createSlice({
  name: 'appProceso',
  initialState: {
    procesoSelected: {},
    data: [],
    total: '',
    params: {},
    allData: [],
    totales: {totalProcesos: 0, totalDocente: 0, totalRenta: 0, totalOperaciones: 0},
    pendientes: [],
    regiones: [],
    id: '',
    entidadesAdministradoras: [],
    establecimientos: [],
    trabajadoras: [],
    procesos: [],
    trabajadorasFicha: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.total = action.payload.totalPages
      state.params = action.payload.param
      state.allData = action.payload.allData

    }).addCase(getAllData.fulfilled, (state, action) => {
      state.allData = action.payload

    }).addCase(getRegiones.fulfilled, (state, action) => {
      state.regiones = action.payload
    })
    .addCase(getEntidadesAdministradoras.fulfilled, (state, action) => {
      state.entidadesAdministradoras = action.payload
    })
    .addCase(getEstablecimientos.fulfilled, (state, action) => {
      state.establecimientos = action.payload
    })
    .addCase(getTrabajadoras.fulfilled, (state, action) => {
      state.trabajadoras = action.payload
    })
    .addCase(getProcesos.fulfilled, (state, action) => {
      state.procesos = action.payload
    })
    .addCase(getPendientes.fulfilled, (state, action) => {
      state.pendientes = action.payload
    })
    .addCase(getPendienteProceso.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
      state.params = action.payload.param
    })
    .addCase(getConvenio.fulfilled, (state, action) => {
      state.procesoSelected = action.payload.selected
    })
    .addCase(getEstadosTotales.fulfilled, (state, action) => {
        state.totales.totalProcesos = action.payload.totalProcesos
        state.totales.totalDocente = action.payload.totalCarreraDocente
        state.totales.totalRenta = action.payload.totalHomologacionRenta
        state.totales.totalOperaciones = action.payload.totalOperaciones
    })
    .addCase(getConvenioByContinuidad.fulfilled, (state, action) => {
      state.procesoSelected = action.payload.selected
      state.id = action.payload.Id
    })
    .addCase(getNominaTrabajadoras.fulfilled, (state, action) => {
      state.trabajadorasFicha = action.payload.data
    })
  }
})


export default appProcesoSlice.reducer
