// ** React Imports
import { Navigate } from 'react-router-dom'
import { useContext, Suspense } from 'react'

// ** Context Imports
import { AbilityContext } from '@src/utility/context/Can'

const PrivateRoute = ({ children, route }) => {
  // ** Hooks & Vars
  
  //TODO: 
  //Configurar Ability

  /*eslint-disable*/
  const ability = useContext(AbilityContext)
  const user = JSON.parse(localStorage.getItem('userData'))

  if (route) {
    //TODO: 
    //Configurar Ability
    let action = null
    let resource = null
    let restrictedRoute = false
    
    if (route.meta) {
      action = route.meta.action
      resource = route.meta.resource
      restrictedRoute = route.meta.restricted
    }
    /*eslint-enable*/
    if (!user) {
      return <Navigate to='/login' />
    }
    //TODO: 
    //Configurar Ability

    if (user && restrictedRoute) {
      return <Navigate to='/' />
    }
     if (user && restrictedRoute && user.role !== 'Administrador Operativo') {
       return <Navigate to='/' />
     }
    if (user && !ability.can(action || 'read', resource)) {
      return <Navigate to='/auth/not-auth' replace />
    }
  }

  return <Suspense fallback={null}>{children}</Suspense>
}

export default PrivateRoute
